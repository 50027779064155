import { render, staticRenderFns } from "./TopSwitch.vue?vue&type=template&id=618f1328&scoped=true"
import script from "./TopSwitch.vue?vue&type=script&lang=js"
export * from "./TopSwitch.vue?vue&type=script&lang=js"
import style0 from "./TopSwitch.vue?vue&type=style&index=0&id=618f1328&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "618f1328",
  null
  
)

export default component.exports