import Vue from 'vue';
import { set, get } from 'lodash';
import { message, Modal } from 'ant-design-vue';
import { HOOK_SNIPPET } from '@/utils/executeSwitchHook';
import { getUserId } from '@utils/userInfo';
import monacoProvider from '@utils/monacoProvider';
import { DEFAULT_LESSCODE, DEFAULT_PROJECT_LESSCODE, generateDefaultModuleCode } from './codes';
import promptForModuleName from '../components/ModuleName';
import { getLanguageFromFilename } from './language';
import { transpileWithESBuild } from '@/components/esbuild';
export const PROJECT_ADVANCE_CODE_PATH = {
    lessCode: 'file:///project/lessCode.js',
    routeGuard: 'file:///project/routeGuard.js',
    headScript: 'file:///project/headScript.html',
    bodyScript: 'file:///project/bodyScript.html',
};
export const getPageletLessCodePath = (pageId, pageletId) => `file:///page/${pageId}/${pageletId}/lessCode.js`;
export const getModuleCodePath = (filename) => `file:///global/${filename}`;
export const JS_MODULE_ALIAS_DTS = 'file:///global/__jsModuleAlias.d.ts';
let jsModuleAliasDtsModel;
// 注册所有应用模块 → 代码补全提示
export const registerProjectModules = async (project) => {
    const monaco = await monacoProvider();
    if (!monaco)
        return;
    import('./monacoGlobalDts').then(m => m.registerMonacoGlobalDep(monaco));
    // 注册全局LessCode $app
    {
        const uri = monaco.Uri.parse(PROJECT_ADVANCE_CODE_PATH.lessCode);
        let model = monaco.editor.getModel(uri);
        if (!model)
            model = monaco.editor.createModel(project?.advanceConfig?.lessCode || DEFAULT_PROJECT_LESSCODE, 'javascript', uri);
        let prevDts;
        const syncToTsServer = () => {
            const value = model.getValue();
            prevDts?.dispose();
            prevDts = monaco.languages.typescript.typescriptDefaults.addExtraLib(value, uri.toString());
        };
        if (!prevDts) {
            syncToTsServer();
            model.onDidChangeContent(syncToTsServer);
        }
    }
    const tsPaths = [];
    Object.values(project?.lessCodeModules ?? {})?.forEach((m) => {
        const uri = monaco.Uri.parse(getModuleCodePath(m.filename));
        const model = monaco.editor.getModel(uri);
        const lang = getLanguageFromFilename(m.filename);
        if (!model)
            monaco.editor.createModel(m.source || m.code, lang, uri);
        if (lang === 'typescript')
            tsPaths.push(m.filename);
    });
    jsModuleAliasDtsModel?.dispose();
    jsModuleAliasDtsModel = monaco.languages.typescript.javascriptDefaults.addExtraLib(tsPaths.map((p) => {
        // 强行把 .ts 源代码直接糊到一起，虽然不雅，但是凑合能用吧
        const uri = monaco.Uri.parse(getModuleCodePath(p));
        const model = monaco.editor.getModel(uri);
        const content = model?.getValue();
        return `declare module "global/${p}" { ${content} \n}\n declare module "global/${p.replace(/\.\w+$/, '')}" { export * from "global/${p}" \n }\n`;
    }).join('\n'), JS_MODULE_ALIAS_DTS);
};
// 注销所有应用模块
export const unregisterProjectModules = async (project) => {
    const monaco = await monacoProvider();
    if (!monaco)
        return;
    Object.values(project?.lessCodeModules ?? {})?.forEach((m) => {
        const uri = monaco.Uri.parse(getModuleCodePath(m.filename));
        const model = monaco.editor.getModel(uri);
        if (model && !model?.isDisposed())
            model?.dispose();
    });
    jsModuleAliasDtsModel?.dispose();
    jsModuleAliasDtsModel = null;
};
export const buildFiles = ({ project, page, pagelets, }) => {
    const files = [];
    const pathMap = {};
    // 应用
    if (project) {
        // 前端公共模块
        const map = project.lessCodeModules;
        const projectModulesPath = 'file:///global';
        files.push({
            path: 'file:///global',
            name: '前端公共模块(global)',
            type: 'dir',
            nodes: Object.keys(map)
                .sort((a, b) => {
                const lowerA = a.toLowerCase();
                const lowerB = b.toLowerCase();
                return lowerA.localeCompare(lowerB) || a.localeCompare(b);
            }) // 按文件名排序
                .map((file) => {
                const { filename } = map[file];
                const language = getLanguageFromFilename(filename);
                return {
                    path: getModuleCodePath(filename),
                    name: filename,
                    title: `前端公共模块(global) / ${filename}`,
                    type: 'file',
                    contentType: 'module',
                    saveType: 'project',
                    language,
                    getValue: () => {
                        const item = get(project, ['lessCodeModules', file]);
                        if (!item)
                            return generateDefaultModuleCode(file);
                        return (item.source ?? item.code) || generateDefaultModuleCode(file);
                    },
                    setValue: async (value) => {
                        const item = get(project, ['lessCodeModules', file]);
                        if (!item)
                            return;
                        // 是否需要编译? 只有 ts 或者使用了可选链语法，或者使用了 esm 语法才需要编译
                        const needTranspile = language !== 'javascript' || /^(export|import)\s|\?\.[\w[]/m.test(value);
                        if (needTranspile) {
                            const out = await transpileWithESBuild(value, filename);
                            item.source = value;
                            item.code = out.code;
                        }
                        else {
                            item.source = undefined;
                            item.code = value;
                        }
                        item.editor = getUserId();
                    },
                    actions: [
                        {
                            icon: 'fa-minus',
                            action: async (workbench) => {
                                const ans = await new Promise((rs) => {
                                    Modal.confirm({
                                        title: '确认删除该模块?',
                                        content: '注意：请确认没有引用该模块的代码',
                                        onOk: () => rs(true),
                                        onCancel: () => rs(false),
                                        zIndex: 2000,
                                    });
                                });
                                if (ans) {
                                    try {
                                        Vue.delete(project.lessCodeModules, filename);
                                        workbench.addDeletedPath(getModuleCodePath(filename));
                                        message.success(`模块 ${filename} 删除成功`);
                                    }
                                    catch (err) {
                                        message.error(`模块 ${filename} 删除失败`);
                                    }
                                }
                            },
                        },
                    ],
                };
            }),
            actions: [
                {
                    icon: 'fa-plus',
                    action: async (workbench) => {
                        // 输入文件名
                        const filename = await promptForModuleName(Object.keys(map));
                        if (filename) {
                            try {
                                const cePath = getModuleCodePath(filename);
                                workbench.handleOpenSelect(projectModulesPath);
                                workbench.addChangedPath(cePath);
                                // 新建前端公共模块
                                Vue.set(project.lessCodeModules, filename, {
                                    filename,
                                    path: `global/${filename}`,
                                    code: generateDefaultModuleCode(filename),
                                    editor: getUserId(),
                                });
                                workbench.changeActiveTab('code');
                                workbench.handleFileSelect({ path: cePath });
                                message.success(`模块 ${filename} 创建成功`);
                            }
                            catch (err) {
                                message.error(`模块 ${filename} 创建失败`);
                            }
                        }
                    },
                },
            ],
        });
        const dirName = '应用';
        files.push({
            path: 'file:///project',
            name: dirName,
            type: 'dir',
            nodes: [
                {
                    path: PROJECT_ADVANCE_CODE_PATH.lessCode,
                    name: 'LessCode',
                    title: `${dirName} / LessCode`,
                    type: 'file',
                    contentType: 'lessCode',
                    saveType: 'project',
                    language: 'javascript',
                    getValue: () => project?.advanceConfig?.lessCode || DEFAULT_PROJECT_LESSCODE,
                    setValue: (value) => {
                        set(project, 'advanceConfig.lessCode', value);
                    },
                },
                {
                    path: PROJECT_ADVANCE_CODE_PATH.routeGuard,
                    name: '全局路由守卫',
                    title: `${dirName} / 全局路由守卫`,
                    type: 'file',
                    contentType: 'script',
                    saveType: 'project',
                    language: 'javascript',
                    getValue: () => project?.advanceConfig?.routeGuard || HOOK_SNIPPET,
                    setValue: (value) => {
                        set(project, 'advanceConfig.routeGuard', value);
                    },
                },
                {
                    path: PROJECT_ADVANCE_CODE_PATH.headScript,
                    name: 'Head 脚本',
                    title: `${dirName} / Head 脚本`,
                    type: 'file',
                    contentType: 'script',
                    saveType: 'project',
                    language: 'html',
                    getValue: () => project?.advanceConfig?.headScript ?? '',
                    setValue: (value) => {
                        set(project, 'advanceConfig.headScript', value);
                    },
                },
                {
                    path: PROJECT_ADVANCE_CODE_PATH.bodyScript,
                    name: 'Body 脚本',
                    title: `${dirName} / Body 脚本`,
                    type: 'file',
                    contentType: 'script',
                    saveType: 'project',
                    language: 'html',
                    getValue: () => project?.advanceConfig?.bodyScript ?? '',
                    setValue: (value) => {
                        set(project, 'advanceConfig.bodyScript', value);
                    },
                },
            ],
        });
    }
    // 页面片
    if (Array.isArray(pagelets)) {
        const pagePath = `/page/${page.pageId}`;
        const pageName = page.name;
        files.push({
            path: `file://${pagePath}`,
            name: `页面: ${pageName}`,
            type: 'dir',
            nodes: pagelets.map((pagelet, index) => {
                const pageletPath = `${pagePath}/${pagelet.id}`;
                const dirName = pagelet.name;
                const pageletNodes = [
                    // lessCode
                    {
                        path: `file://${pageletPath}/lessCode.js`,
                        name: 'LessCode',
                        title: `${pageName} / ${dirName} / LessCode`,
                        type: 'file',
                        contentType: 'lessCode',
                        saveType: 'page',
                        language: 'javascript',
                        pageletId: pagelet.id,
                        getValue: () => get(pagelets, `${index}.lessCode`, DEFAULT_LESSCODE),
                        setValue: (value) => {
                            set(pagelets, `${index}.lessCode`, value);
                        },
                    },
                    // 布局 Schema
                    {
                        path: `file://${pageletPath}/layout.json`,
                        name: '布局',
                        title: `${pageName} / ${dirName} / 布局`,
                        type: 'file',
                        contentType: 'layoutSchema',
                        saveType: 'page',
                        language: 'json',
                        pageletId: pagelet.id,
                        getValue: () => JSON.stringify(get(pagelets, `${index}.layout`), null, 2),
                        setValue: (value) => {
                            try {
                                set(pagelets, `${index}.layout`, JSON.parse(value));
                            }
                            catch (err) {
                                throw new Error('布局格式错误, 保存失败');
                            }
                        },
                    },
                ];
                // 数据源 Schema
                if (pagelet?.xySources) {
                    const xySourcesValue = JSON.stringify(get(pagelets, `${index}.xySources`), null, 2);
                    pageletNodes.push({
                        path: `file://${pageletPath}/xySources.json`,
                        name: '数据源',
                        title: `${pageName} / ${dirName} / 数据源`,
                        type: 'file',
                        contentType: 'layoutSchema',
                        saveType: 'page',
                        language: 'json',
                        pageletId: pagelet.id,
                        getValue: () => xySourcesValue,
                        setValue: (value) => {
                            try {
                                set(pagelets, `${index}.xySources`, JSON.parse(value));
                            }
                            catch {
                                throw new Error('数据源格式错误, 保存失败');
                            }
                        },
                    });
                }
                // 页面变量 Schema
                if (pagelet?.stateSchema?.fields) {
                    const fieldsValue = JSON.stringify(get(pagelets, `${index}.stateSchema.fields`), null, 2);
                    pageletNodes.push({
                        path: `file://${pageletPath}/stateSchema/fields.json`,
                        name: '页面变量',
                        title: `${pageName} / ${dirName} / 页面变量`,
                        type: 'file',
                        contentType: 'layoutSchema',
                        saveType: 'page',
                        language: 'json',
                        pageletId: pagelet.id,
                        getValue: () => fieldsValue,
                        setValue: (value) => {
                            try {
                                set(pagelets, `${index}.stateSchema.fields`, JSON.parse(value));
                            }
                            catch {
                                throw new Error('页面变量格式错误, 保存失败');
                            }
                        },
                    });
                }
                // 传给页面片的变量 Schema
                if (pagelet?.propsSchema?.fields) {
                    const fieldsValue = JSON.stringify(get(pagelets, `${index}.propsSchema.fields`), null, 2);
                    pageletNodes.push({
                        path: `file://${pageletPath}/propsSchema/fields.json`,
                        name: '传给页面片的变量',
                        title: `${pageName} / ${dirName} / 传给页面片的变量`,
                        type: 'file',
                        contentType: 'layoutSchema',
                        saveType: 'page',
                        language: 'json',
                        pageletId: pagelet.id,
                        getValue: () => fieldsValue,
                        setValue: (value) => {
                            try {
                                set(pagelets, `${index}.propsSchema.fields`, JSON.parse(value));
                            }
                            catch {
                                throw new Error('传给页面片的变量格式错误, 保存失败');
                            }
                        },
                    });
                }
                return {
                    path: `file://${pageletPath}`,
                    name: dirName,
                    type: 'dir',
                    nodes: pageletNodes,
                };
            }),
        });
    }
    const walk = (node) => {
        if (Array.isArray(node.nodes)) {
            node.nodes.forEach((n) => walk(n));
        }
        pathMap[node.path] = node;
    };
    files.forEach(node => walk(node));
    return { files, pathMap };
};
