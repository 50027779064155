export const XyInnerPageSelectorMeta = {
  settingsSchema: null,
  component: () => (
    {
      component: import(/* webpackChunkName: "xyInnerPageSelector" */'./xy-inner-page-selector.vue'),
    }
  ),
  hideLabel: false,
};


export const XyInnerPageletSelectorMeta = {
  settingsSchema: null,
  component: () => (
    {
      component: import(/* webpackChunkName: "xyInnerPageletSelector" */'./xy-inner-pagelet-selector.vue'),
    }
  ),
  hideLabel: false,
};


export const XyInnerFlowNodePageletConfigMeta = {
  settingsSchema: null,
  component: () => (
    {
      component: import(/* webpackChunkName: "xyInnerFlowNodePageletConfig" */'./xy-inner-flow-node-pagelet-config.vue'),
    }
  ),
  hideLabel: false,
};

export const XyPageContainerTipsMeta = {
  settingsSchema: null,
  component: () => (
    {
      component: import(/* webpackChunkName: "XyPageContainerTips" */'./xy-page-container-tips.vue'),
    }
  ),
  hideLabel: false,
};
