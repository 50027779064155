const lsKey = 'xy_new_workbench_enabled';
export function setNewWorkbenchEnabled(newWorkbench) {
    localStorage.setItem(lsKey, newWorkbench ? '1' : '0');
}
export function isNewWorkbenchEnabled() {
    // try catch 防止在单测中出现异常
    try {
        return localStorage.getItem(lsKey) === '1';
    }
    catch (error) {
        console.error(error);
        return false;
    }
}
/**
 * 默认跳转新版本
 */
export function setDefaultWorkbenchEnabled() {
    try {
        const val = localStorage.getItem(lsKey);
        if (val === null || val === undefined) {
            setNewWorkbenchEnabled(true);
        }
    }
    catch (error) {
        console.error(error);
    }
}
