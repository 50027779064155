import { computed, inject, onBeforeMount, onMounted, onUnmounted } from '@vue/composition-api';
import { INJECT_UICORE_BUS, INJECT_UICORE_RENDERER } from '@tencent/ui-core/lib/utils/consts';
import { waitForRendererMounted } from '@/utils/global/api';
import { runtimeHook } from '@/hooks/runtimeHook';
const createdEvent = {
    id: 'created',
    name: '初始化',
    desc: '此时数据源可能还没就绪， **不能调用数据源方法**',
};
const rendererMountedEvent = {
    id: 'rendererMounted',
    name: '渲染完成',
    desc: '此时页面渲染完成',
    arguments: [
        { type: 'any', description: '当前页面 renderer 实例' },
    ],
};
const allDataSourcesReadyEvent = {
    id: 'allDataSourcesReady',
    name: '数据源就绪',
    desc: '此时可以调用数据源方法了。如果数据源有配置“打开页面时自动拉数据”，那么会等待拉取完成，才执行这里的逻辑',
    arguments: [
        { type: 'any', description: '当前页面 renderer 实例' },
    ],
};
const destroyedEvent = {
    id: 'destroyed',
    name: '销毁',
    desc: '',
};
// 页面片相关事件
export const EVENTS = [
    createdEvent,
    rendererMountedEvent,
    allDataSourcesReadyEvent,
    destroyedEvent,
];
// 页面片相关事件触发逻辑
export const usePageletEvent = ({ emit }) => {
    const uc = inject(INJECT_UICORE_BUS, null);
    const renderer = inject(INJECT_UICORE_RENDERER, null);
    const isDesignMode = computed(() => uc?.isDesignMode);
    onBeforeMount(() => {
        if (isDesignMode.value)
            return;
        // 初始化
        emit(createdEvent.id);
        // 数据源就绪
        const sources = renderer?.sources;
        if (sources) {
            const promises = [];
            Object.values(sources).forEach((source) => {
                promises.push(source.waitForReady());
            });
            Promise.all(promises)
                .then(() => runtimeHook.pageBeforeAllDataSourcesReady.promise(renderer))
                .then(() => {
                emit(allDataSourcesReadyEvent.id, renderer);
            });
        }
    });
    onMounted(async () => {
        if (isDesignMode.value)
            return;
        await waitForRendererMounted(renderer);
        emit(rendererMountedEvent.id, renderer);
    });
    onUnmounted(() => {
        if (isDesignMode.value)
            return;
        // 销毁
        emit(destroyedEvent.id);
    });
};
