import { defineComponent, onMounted } from '@vue/composition-api';
import PermissionCheckbox from './PermissionCheckbox';
import { useBuiltinPermissionList } from './utils';
export default defineComponent({
    name: 'CustomPermission',
    emits: ['change'],
    props: {
        projectId: {
            type: String,
            default: '',
            required: true,
        },
        env: {
            type: String,
            default: 'dev',
        },
        role: {
            type: String,
            default: '',
            required: true,
        },
        permissions: {
            type: Array,
            required: true,
        },
        rolePerms: {
            type: Array,
            required: true,
        },
        readonly: {
            type: Boolean,
            default: false,
        },
        maxHeight: {
            type: String,
            default: '',
        },
    },
    setup(props) {
        const { keyword, filterPermissions, fetchBuiltinPermissionList } = useBuiltinPermissionList(props.projectId);
        onMounted(() => fetchBuiltinPermissionList(props.env));
        return {
            keyword,
            filterPermissions,
            fetchBuiltinPermissionList,
        };
    },
    render() {
        const change = (e) => {
            const changePerm = e.data;
            const { permission } = changePerm;
            const builtin = this.filterPermissions.find(x => x.name === permission);
            if (builtin)
                changePerm.permName = builtin.cname;
            this.$emit('checkChange', e);
        };
        const columns = [
            {
                title: '权限ID',
                colKey: 'name',
                cell: (h, { row }) => (<span>
            {row.name.includes('.project') ? (<t-tooltip placement="right">
                <template slot="content">
                  <span>批量授权应用中所有页面，谨慎使用</span>
                </template>
                <span>{row.name}</span>
                <t-icon name="info-circle" style="margin-left:8px"/>
              </t-tooltip>) : (row.name)}
          </span>),
            },
            {
                title: '权限名',
                colKey: 'cname',
            },
            {
                title: '持有权限',
                cell: (h, { row: record }) => (<PermissionCheckbox projectId={this.projectId} env={this.env} role={this.role} rolePerms={this.rolePerms} readonly={this.readonly} permission={record.name} onCheckChange={change} onChange={(v) => this.$emit('change', v)}/>),
            },
        ];
        return (<div>
        <div style="margin-bottom:12px">
          <t-input v-model={this.keyword} placeholder="请输入权限名进行搜索" style="width:240px">
            <t-icon slot="suffixIcon" name="search"/>
          </t-input>
        </div>
        <div>
          <t-table columns={columns} data={this.filterPermissions} rowKey="name" hover maxHeight={this.maxHeight} pagination={false} scroll={{ type: 'virtual' }}></t-table>
        </div>
      </div>);
    },
});
