import { defineComponent, toRef, ref } from '@vue/composition-api';
import PermissionCheckbox from './PermissionCheckbox';
import { PermissionTypeMap, PermissionType } from './constants';
import { useFilterPermissions } from './utils';
export default defineComponent({
    name: 'CustomPermission',
    emits: ['change'],
    props: {
        projectId: {
            type: String,
            default: '',
            required: true,
        },
        env: {
            type: String,
            default: 'dev',
        },
        role: {
            type: String,
            default: '',
            required: true,
        },
        permissions: {
            type: Array,
            required: true,
        },
        rolePerms: {
            type: Array,
            required: true,
        },
        readonly: {
            type: Boolean,
            default: false,
        },
        maxHeight: {
            type: String,
            default: '',
        },
        isPermSet: {
            type: Boolean,
            default: false,
        },
    },
    setup(props) {
        const permissionsRef = toRef(props, 'permissions');
        const { keyword, filterPermissions } = useFilterPermissions(permissionsRef, ref([]));
        return {
            keyword,
            filterPermissions,
        };
    },
    render() {
        const columns = [
            {
                title: '权限ID',
                colKey: 'name',
                width: 400,
            },
            {
                title: '权限名',
                colKey: 'cname',
            },
            {
                title: '类型',
                colKey: 'type',
                cell: (h, { row }) => PermissionTypeMap[row.type] || row.type,
            },
            {
                title: '持有权限',
                cell: (h, { row: record }) => (<PermissionCheckbox projectId={this.projectId} env={this.env} role={this.role} rolePerms={this.rolePerms} readonly={this.readonly} permission={record.name} onCheckChange={(e) => this.$emit('checkChange', e)} onChange={(v) => this.$emit('change', v)}/>),
            },
        ];
        const types = this.isPermSet
            ? [PermissionType.MODULE, PermissionType.API]
            : [PermissionType.MODULE, PermissionType.API, PermissionType.SET];
        return (<div>
        <div style="margin-bottom:12px">
          <t-input v-model={this.keyword} placeholder="请输入权限名进行搜索" style="width:240px">
            <t-icon slot="suffixIcon" name="search"/>
          </t-input>
        </div>
        <div>
          <t-table columns={columns} data={this.filterPermissions.filter(x => types.includes(x.type))} rowKey="name" pagination={false} maxHeight={this.maxHeight} hover scroll={{ type: 'virtual' }}></t-table>
        </div>
      </div>);
    },
});
