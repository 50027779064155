<template>
  <div
    v-if="showWidgets.length > 0"
    :class="!isMobileOrNarrowScreen ? 'flex-container' : 'mini-container'"
  >
    <div
      v-for="(widget, index) in showWidgets"
      :key="index"
    >
      <component
        :is="getComponent(widget)"
        :project-id="projectId"
        :config="widget.config"
        :theme="theme"
        :hide-widgets="hideWidgets"
      />
    </div>
  </div>
</template>

<script>
import navWidget from '.';

export default {
  components: {
    ...navWidget.getViewComponents(),
  },
  props: {
    projectId: {
      type: String,
      default: '',
    },
    widgets: {
      type: Array,
      default: () => [],
    },
    isMobileOrNarrowScreen: {
      type: Boolean,
      default: false,
    },
    theme: {
      type: String,
      default: 'light',
    },
    hideWidgets: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {};
  },
  computed: {
    showWidgets() {
      return this?.widgets?.filter(widget => !!widget.isShow) ?? [];
    },
  },
  methods: {
    getComponent(w) {
      return navWidget.register(w.type)?.viewComponent?.name;
    },

  },
};
</script>

<style lang="scss">
.flex-container {
  display: flex;
  align-items: center;
}
.mini-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
  gap: 8px;
}
</style>
