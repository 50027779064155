import { computed } from '@vue/composition-api';

export const useRouter = () => {
  const router = computed(() => window?.xy_vue_instance?.$router);
  const route = computed(() => window?.xy_vue_instance?.$route);

  /**
   * 跳转到目标地址。
   *
   * 如果是按着 Cmd / Ctrl / Shift 点击，会在新窗口打开
   *
   * @param {import('vue-router').RawLocation} targetLocation
   * @param {'push' | 'replace' | 'newWindow'} [mode]
   */
  const navigateTo = (targetLocation, mode) => {
    const $router = router.value;
    if (!$router) return;
    if (!mode) {
      if (window.event && (window.event.button === 1 || window.event.ctrlKey || window.event.shiftKey || window.event.metaKey)) mode = 'newWindow';  // eslint-disable-line no-param-reassign
      else mode = 'push'; // eslint-disable-line no-param-reassign
    }

    if (mode === 'newWindow') {
      // 新窗口打开
      const resolved = $router.resolve(targetLocation);
      if (resolved.href) window.open(resolved.href, '_blank', 'noopener=yes');
      return;
    }
    $router[mode](targetLocation);
    return;
  };
  return {
    router,
    route,
    navigateTo,
  };
};
