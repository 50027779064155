import { defineComponent, ref, watch } from '@vue/composition-api';
import { usePermission } from './utils';
import { pagePermissionTooltip } from './constants';
import { injectPermState } from './PermState';
export default defineComponent({
    name: 'PermissionCheckbox',
    emits: ['change'],
    props: {
        projectId: {
            type: String,
            required: true,
        },
        env: {
            type: String,
            default: 'dev',
        },
        role: {
            type: String,
            required: true,
        },
        rolePerms: {
            type: Array,
            required: true,
        },
        permission: {
            type: String,
            default: '',
            required: true,
        },
        readonly: {
            type: Boolean,
            default: false,
        },
        pageId: {
            type: String,
            default: '',
        },
        label: {
            type: String,
            default: '',
        },
        triggerCheckChange: {
            type: Function,
            default: null,
        },
    },
    setup(props) {
        const { check, checkProject } = usePermission(props.projectId);
        const permState = injectPermState();
        const fromPageMenu = ref(!!props.label);
        const isCheck = ref(false);
        const isProjectChecked = ref(false);
        watch(() => [props.rolePerms, props.permission, props.pageId], () => {
            isCheck.value = check(props.rolePerms, props.role, props.permission, props.pageId);
            isProjectChecked.value = checkProject(props.rolePerms, props.role, props.permission);
        }, {
            immediate: true,
            deep: true,
        });
        return { fromPageMenu, isCheck, isProjectChecked, permState };
    },
    render() {
        const checkChange = (checked, permission, pageId = '') => {
            let rolePerms = [...this.rolePerms];
            const data = { permission, pageId };
            let type = 'checked';
            if (checked) {
                rolePerms.push(data);
            }
            else {
                rolePerms = rolePerms.filter(p => p.permission !== permission || p.pageId !== pageId);
                type = 'unchecked';
            }
            if (this.permState.panguMappingEnabled.value)
                this.$emit('change', rolePerms);
            else
                this.$emit('checkChange', { type, data });
        };
        const renderCheck = (permission, pageId = '') => {
            if (this.readonly) {
                const icon = (this.isCheck || this.isProjectChecked) ? 'check-circle' : 'close-circle';
                return <label style="margin-right:8px">
          <t-icon name={icon} style={{ color: (this.isCheck || this.isProjectChecked) ? '#52c41a' : 'unset' }}/>
          <span style="padding: 0 8px;display:inline-block;transform:translateY(-1px)">{this.label}</span>
        </label>;
            }
            const node = <t-checkbox checked={this.isCheck || this.isProjectChecked} disabled={this.isProjectChecked} onChange={(checked) => checkChange(checked, permission, pageId)}>
        {this.label}
      </t-checkbox>;
            return <span>
        {this.isProjectChecked
                    ? <t-tooltip content={pagePermissionTooltip[permission].warn}>
                {node}
              </t-tooltip>
                    : node}
      </span>;
        };
        return renderCheck(this.permission, this.pageId);
    },
});
