import Vue from 'vue';
import { getCurrentInstance } from '@vue/composition-api';
export const idToRouteName = (materialId) => `workbench-${materialId}`;
export const routeNameToId = (routeName) => routeName.replace(/^workbench-/, '');
const hookMergedVMUidKey = '__wuji_hook_merge_component_uid__';
/**
 * 参考实现 https://github.com/vuejs/composition-api/issues/49#issuecomment-701015268
 * 这里是注册相当于注册的vue options上的，所有数据必须从this里面拿
 */
function onHook(name, callback) {
    const vm = getCurrentInstance();
    const merge = Vue.config.optionMergeStrategies[name];
    if (vm && merge && vm.uid) {
        const prototype = Object.getPrototypeOf(vm.proxy.$options);
        // 原型上记录第一个实例的uid
        if (!prototype[hookMergedVMUidKey]) {
            prototype[hookMergedVMUidKey] = vm.uid;
        }
        ;
        // 当前组件的第一个实例的uid,只允许这个uid的实例注册一次原型上的hook，这个vm
        const firstVMUid = prototype[hookMergedVMUidKey];
        if (vm.uid !== firstVMUid) {
            return;
        }
        prototype[name] = merge(vm.proxy.$options[name], callback);
    }
}
export function onBeforeRouteEnter(callback) {
    return onHook('beforeRouteEnter', callback);
}
export function onBeforeRouteUpdate(callback) {
    return onHook('beforeRouteUpdate', callback);
}
export function onBeforeRouteLeave(callback) {
    return onHook('beforeRouteLeave', callback);
}
