import { FormObject } from './form-object';
export class BizContainer {
    static instance;
    bizMap = new Map();
    fieldsMap = new Map();
    constructor() { }
    register(bizId, fields, biz) {
        this.bizMap.set(bizId, biz);
        this.fieldsMap.set(bizId, fields);
    }
    createFormObject(options) {
        return new FormObject(options, this.bizMap);
    }
    getBizField(path) {
        const dotPos = path.indexOf('.');
        const bizId = path.slice(0, dotPos);
        const field = path.slice(dotPos + 1);
        return this.fieldsMap.get(bizId)?.[field];
    }
    // eslint-disable-next-line @typescript-eslint/member-ordering
    static getInstance() {
        if (!BizContainer.instance) {
            BizContainer.instance = new BizContainer();
        }
        return BizContainer.instance;
    }
}
export const bizContainer = BizContainer.getInstance();
