import { computed } from '@vue/composition-api';
import { useStore } from 'vuex-composition-helpers';
import { getUserId } from '@utils/userInfo';
export default function useIds() {
    const store = useStore();
    const isRuntime = window.w?.mode === 'runtime';
    const projectIdRef = computed(() => {
        if (isRuntime) {
            return window.RUNTIME_CONFIGS.projectId;
        }
        return store.state.project.projectInfo.id;
    });
    const envIdRef = computed(() => {
        if (isRuntime) {
            return window.w.env;
        }
        return store.state.app.env;
    });
    const currentBranchIdRef = computed(() => store.state.branch?.currentBranch?.branch);
    return {
        projectIdRef,
        get projectId() {
            return projectIdRef.value;
        },
        get userId() {
            return getUserId();
        },
        currentBranchIdRef,
        get currentBranchId() {
            return currentBranchIdRef.value;
        },
        envIdRef,
        get env() {
            return envIdRef.value;
        },
        get envId() {
            return envIdRef.value;
        },
    };
}
