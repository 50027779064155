import useLayout from '@/composables/useLayout';
import { useStore } from '@/store';
import { getAppParams } from '@/utils/path';
import UserApprove from '@pages/projectGroup/SsoUserApprove/index';
import { defineComponent, reactive, onMounted } from '@vue/composition-api';
import styles from './UserApprove.module.scss';
export default defineComponent({
    setup() {
        const { project } = useLayout();
        const store = useStore();
        const state = reactive({
            loading: true,
            hasNoPermission: true,
            projectId: '',
            groupId: '',
        });
        onMounted(async () => {
            const projectId = project.value?.id;
            const env = getAppParams()?.env ?? 'dev';
            const { groupId, isAdmin } = await store.dispatch('runtime/getGroupInfo', { projectId, env });
            if (!store.state.projectGroup.groupInfo?.id) {
                // hack! 因为 UserAutoCompleteInput 组件耦合了编辑时的 store
                // 后续可能不应该这么操作
                store.commit('updateProjectGroup', { groupInfo: { id: groupId } });
            }
            state.groupId = groupId;
            state.projectId = projectId;
            state.loading = false;
            state.hasNoPermission = !isAdmin;
        });
        return () => {
            if (state.loading)
                return <a-spin />;
            if (state.hasNoPermission)
                return (<a-layout style={{ background: '#fff', padding: '30px', height: '100%' }}>
          <a-result status="error" title="无权限" sub-title="您不是应用组管理员"/>
        </a-layout>);
            return <div class={styles.wrapper}>
        <UserApprove groupId={state.groupId}/>
      </div>;
        };
    },
});
