import { AsyncSeriesHook } from 'tapable';
const createRuntimeHook = () => ({
    // 页面配置加载
    pageConfigLoaded: new AsyncSeriesHook(['pageConfig']),
    // 页面所有数据源就绪，准备告诉页面片 allDataSourcesReady 事件之前
    pageBeforeAllDataSourcesReady: new AsyncSeriesHook(['renderer']),
});
export const runtimeHook = createRuntimeHook();
export function registerRuntimeHook(hookType, hookName, hookFunc) {
    const hook = runtimeHook[hookType];
    hook.tapPromise(hookName, hookFunc);
}
;
export function unregisterRuntimeHook(hookType, hookName) {
    const hook = runtimeHook[hookType];
    hook.taps = hook.taps.filter(item => item.name !== hookName);
}
;
const initRuntimeHook = () => {
    window.xy_runtime_hook = {
        register: registerRuntimeHook,
        unregister: unregisterRuntimeHook,
    };
};
export default initRuntimeHook;
