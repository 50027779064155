import { notification } from 'ant-design-vue';
import { message } from '@/utils/wuji-message';
export class FormCallResult {
    items;
    type = 'success';
    message = '';
    get failDesc() {
        if (this.type === 'success') {
            return '';
        }
        const failureItems = this.items.filter(item => !item.succ);
        const desc = failureItems.map(item => `${item.bizId}服务: ${item.message}`).join(';\n');
        return desc;
    }
    constructor(items) {
        this.items = items;
        const successCount = items.filter(item => item.succ).length;
        if (successCount === items.length) {
            this.type = 'success';
            this.message = '保存成功';
            return;
        }
        if (successCount > 0 && successCount < items.length) {
            this.type = 'partial';
            this.message = '有部分字段保存失败';
            return;
        }
        this.type = 'fail';
        this.message = '保存失败';
    }
    showMessage() {
        if (this.type === 'success') {
            message.success(this.message);
            return;
        }
        notification.open({
            message: this.message,
            description: this.failDesc,
        });
    }
}
