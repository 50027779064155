import { CATEGORY } from './const';
import { getFlowJobPageURL } from '@/components/builtinPagePlugins/flowPagePlugin/utils/url';
import { flowApi } from '@/services';
import { XyFlowSelectorMeta, XyFlowAtomDataEditorMeta, XyFlowApproveDataEditorMeta } from '../paramsFormComponents';
import { message } from 'ant-design-vue';
import { isEmpty } from 'lodash';

/**
 * 流程引擎 - 行为原子
 */
// 发起流程
export const flowStartAtom = {
  id: 'xyEvent:flow:startFlow',
  name: '发起高级流程',
  category: CATEGORY.FLOW,
  paramsSchema: {
    type: 'object',
    fields: [
      {
        id: 'flowId',
        type: 'string',
        title: '流程ID',
        ui: {
          type: 'XyFlowSelector',
        },
      },
      {
        id: 'flowParams',
        type: 'string',
        title: '流程参数',
        ui: { type: 'XyFlowAtomDataEditor' },
        'uc:allowExpr': false,
      },
      {
        id: 'showSuccessTooltip',
        type: 'boolean',
        title: '发起流程成功提示',
        default: true,
      },
      {
        id: 'autoOpenDetail',
        type: 'boolean',
        title: '自动打开流程单详情页',
        default: false,
      },
    ],
  },
  returnValue: {
    type: 'object',
    description: '流程任务信息, 其他字段请参考创建流程接口协议',
    fields: [
      {
        id: 'jobId',
        type: 'string',
        description: '任务ID',
      },
      {
        id: 'url',
        type: 'string',
        description: '任务详情页链接',
      },
      {
        id: 'title',
        type: 'string',
        description: '任务标题',
      },
    ],
  },
  paramsFormComponents: [
    XyFlowSelectorMeta,
    XyFlowAtomDataEditorMeta,
  ],
  async execute(ctx, params) {
    const { projectId, env: envId } = window.w;

    const { flowId, flowParams, showSuccessTooltip, autoOpenDetail } = params;

    const job = await flowApi.startFlow(projectId, flowId, { ...ctx.tryEval(flowParams) });
    const jobId = job.id;
    const url = getFlowJobPageURL({ projectId, flowId, jobId, envId });

    if (showSuccessTooltip) message.success('成功发起流程');
    if (autoOpenDetail) window.open(url);

    return { ...job, jobId, url };
  },
};

export const flowOpenStartLinkAtom = {
  id: 'xy:source:workflowOpenStartLink',
  name: '跳转流程发起页',
  category: CATEGORY.FLOW,
  paramsSchema: {
    type: 'object',
    fields: [
      {
        id: 'type',
        type: 'string',
        title: '流程类型',
        default: 'flow',
        hidden: 'true',
      },
      {
        id: 'flowId',
        type: 'string',
        title: '流程ID',
        ui: { type: 'XyFlowSelector' },
      },
      {
        id: 'approveData',
        type: 'string',
        title: '初始化审批数据(可选)',
        ui: { type: 'XyFlowApproveDataEditor' },
        description: 'json 串: `{"name":"wuji"}`, 或者 js 对象: `{{ flowState.resp }}`, 也可以混用 `{"name":"{{w.user.name}}"}`',
      },
    ],
  },
  paramsFormComponents: [
    XyFlowSelectorMeta,
    XyFlowApproveDataEditorMeta,
  ],
  async execute(ctx, params) {
    const { flowId, approveData } = params;

    let data = null;
    try {
      if (typeof approveData === 'string') {
        data = JSON.parse(approveData);
      } else {
        data = approveData;
      }
      if (!isEmpty(data)) {
        data = encodeURIComponent(JSON.stringify(data));
      } else {
        data = '';
      }
    } catch (e) {
      data = '';
      const msg = '审批数据应为有效的 JSON';
      throw new Error(msg);
    }

    const { projectId, env: envId } = window.w;

    try {
      // 新流程
      const url = getFlowJobPageURL({ projectId, flowId, envId, hashData: data });
      window.open(url);
    } catch (error) {
      throw new Error('发起流程失败');
    }
  },
};

export default [
  flowStartAtom,
  flowOpenStartLinkAtom,
];
