<template>
  <div
    class="xy-page-container"
  >
    <!-- 加载中 -->
    <template v-if="isLoading">
      <!-- 静默 -->
      <template v-if="loadingMode === 'silence'" />
      <!-- 占位 -->
      <a-skeleton v-else-if="loadingMode === 'skeleton'" />
      <!-- 默认 -->
      <div
        v-else
        class="xy-page-container-tips"
      >
        <w-sys-spin
          class="loading-content"
          tip="数据加载中，请稍后..."
        />
      </div>
    </template>
    <!-- 已加载 -->
    <template v-else>
      <!-- 页面(片)选择 -->
      <div
        v-if="!isSelected"
        class="xy-page-container-tips"
      >
        <span>请选择页面(片)</span>
      </div>
      <!-- 数据加载失败 -->
      <div
        v-else-if="!isReady"
        class="xy-page-container-tips"
      >
        <span>页面({{ pageId }})不存在或无权访问，请联系应用管理员</span>
      </div>
      <!-- 正常渲染嵌套页面(片) -->
      <w-sys-page
        v-else
        ref="w-sys-pagelet"
        :mode="w.mode"
        :project-config="projectConfig"
        :config="config"
        :page-list="pageList"
        :merge-params="mergeParams"
        :patch-params-object="pageDataBase.patchParamsObject"
        :params="pageDataBase.params"
        :pagelet-id="renderPageletId"
        :hide-loading-indicator="isHideLoadingIndicator"
        :container="this"
        :main="false"
      />
    </template>
  </div>
</template>
<script>
import get from 'lodash/get';
import forEach from 'lodash/forEach';
import { stringInterop, vueDeepSet } from '@tencent/ui-core/lib/utils';
import { toPathParts } from '@tencent/data-schema-core/lib/utils/path';
import pageLoader from '@loaders/page/loader';
import { getGeneratedPage } from '@/loaders/pageGenerator';
import useJobService from '@/composables/flow/useJobService';
import useFlowService from '@/composables/flow/useFlowService';
import { isPreviewMode } from '@/composables/pagePreview/use-preview';

export default {
  name: 'XyPageContainer',
  inject: {
    w: { from: 'w', default: null },
    parentRenderer: { from: 'uicore:renderer', default: null },
  },
  props: {
    type: {
      type: String,
      default: 'page',
    },
    pageId: {
      type: String,
      default: '',
    },
    pageletId: {
      type: String,
      default: '',
    },
    flowNodePageletConfig: {
      type: Object,
      default: null,
    },
    mergeParams: {
      type: Boolean,
      default: false,
    },
    params: {
      type: [Array, Object],
      default: () => ([]),
    },
    silence: {
      type: Boolean,
      default: false,
    },
    loadingMode: {
      type: String,
      default: 'default',
    },
    hideLoadingIndicator: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isLoading: false,

      projectConfig: null,
      config: null,
      pageList: null,
    };
  },
  computed: {
    // 供外部使用的 ui-core renderer 实例
    renderer() {
      return this.$refs?.['w-sys-pagelet']?.$refs?.wSysPagelet?.ucInstance;
    },
    // 是否已经选择了页面/页面片
    isSelected() {
      if (this.type === 'flowNodePagelet') return !!(this.flowNodePageletConfig?.flowId && this.flowNodePageletConfig.nodeId);
      return !!(this.renderPageId && this.renderPageletId);
    },
    // 数据时候加载完毕
    isReady() {
      return !!(this.projectConfig && this.config && this.pageList);
    },
    isHideLoadingIndicator() {
      return this.hideLoadingIndicator || this.loadingMode !== 'default';
    },
    isRuntime() {
      return this.w?.mode === 'runtime' && !isPreviewMode();
    },
    env() {
      return this.w?.env;
    },
    projectId() {
      return this.w?.projectInfo?.id;
    },
    renderPageId() {
      return this.type === 'page' ? this.pageId : this.w?.pageInfo?.pageId;
    },
    renderPageletId() {
      return this.type !== 'pagelet' ? 'default' : this.pageletId;
    },
    store() {
      return (this.$store || window.xy_runtime_utils.store);
    },
    pageDataBase() {
      const { parentRenderer: renderer } = this;
      const params = {};
      const patchParamsObjectBody = [];  // Functions

      forEach(this.params, (paramItem) => {
        const { key, value: valueRaw, bind } = paramItem;

        if (bind) {
          // 使用双向绑定的方式结合到当前页面的数据源
          const bindParts = toPathParts(bind);
          patchParamsObjectBody.push((x) => {
            Object.defineProperty(x, key, {
              configurable: false,
              enumerable: true,
              get: () => get(renderer, bindParts),
              set: (value) => {
                vueDeepSet(renderer, bindParts, value);
              },
            });
          });
        } else {
          // 使用表达式的方式过来，由于 wSysPage 会做深层拷贝，所以会失去绑定
          const value = stringInterop(valueRaw, renderer);
          params[key] = value;
        }
      });

      const patchParamsObject = patchParamsObjectBody.length
        ? (...args) => patchParamsObjectBody.forEach(fn => fn(...args))
        : null;

      return {
        patchParamsObject,
        params,
      };
    },
  },
  created() {
    this.$watch(() => {
      if (!this.projectId || !this.isSelected) return false;

      if (this.type === 'flowNodePagelet') return `${this.projectId}/${JSON.stringify(this.flowNodePageletConfig)}`;
      return this.projectId && this.renderPageId &&  this.renderPageletId && `${this.projectId}/${this.renderPageId}/${this.renderPageletId}`;
    }, async (ready) => {
      this.isLoading = true;
      if (ready) {
        const { projectId } = this;
        const pageId = this.type === 'flowNodePagelet' ? this.pageId : this.renderPageId;

        await Promise.all([
          this.initProject(projectId),
          this.initPageList(projectId),
          this.initPage(projectId, pageId),
        ]);
      }
      this.isLoading = false;
    }, { immediate: true });

    if (isPreviewMode()) {
      this.$watch(() => this.w.pageInfo, () => {
        this.config = this.w.pageInfo;
      });
    }
  },
  methods: {
    refresh() {
      // 刷新内嵌页面中的所有页面片
      this.$refs['w-sys-pagelet']?.reloadUcRenderer('*');
    },
    // 获取应用数据
    async initProject(projectId) {
      try {
        let project = null;
        if (projectId) {
          const { env } = this;
          if (this.isRuntime) {
            // 运行时: 使用 vuex 缓存
            project = await this.store.dispatch('runtime/getProject', { projectId, env });
          } else {
            // 编辑时: 从上下文中获取
            project = this.w?.projectInfo;
          }
        }
        this.projectConfig = project;
      } catch (e) {
        console.error('[xy-page-container].initProject', e);
      }
    },
    // 获取页面列表数据
    async initPageList(projectId) {
      try {
        let pageList = [];
        const { env } = this;
        if (projectId) {
          if (this.isRuntime) {
            // 运行时: 使用 vuex 缓存
            pageList = await this.store.dispatch('runtime/getBasicPageList', { projectId, env });
          } else {
            // 编辑时: 从上下文中获取
            pageList = this.w?.pageList;
          }
        }
        this.pageList = pageList;
      } catch (e) {
        console.error('[xy-page-container].initPageList', e);
      }
    },
    // 获取页面数据
    async initPage(projectId, pageId) {
      try {
        let page = null;
        const { env, type } = this;

        if (type === 'flowNodePagelet') {
          // 流程页面片
          const { flowId, nodeId } = this.flowNodePageletConfig;
          const envId = env;
          const jobId = this.pageDataBase.params.jobId || '';

          // 拉取自定义页面片
          let preferredPageletId;
          if (jobId) {
            // 通过 job 获取流程图
            const service = useJobService({ projectId, envId });
            const job = (await service.getForRuntime(jobId));
            preferredPageletId = job.nodeId2PageletIdLUT?.[nodeId];
          } else {
            // 通过流程图拉取
            const service = useFlowService({ projectId, envId });
            const flow = await service.getForRuntime(flowId);
            preferredPageletId = flow.nodeId2PageletIdLUT?.[nodeId];
          }

          /* eslint-disable-next-line no-param-reassign */
          pageId = preferredPageletId || `!generated:${JSON.stringify({ type: 'flowNodePagelet', nodeId, flowId })}`;
        }

        if (projectId && pageId) {
          const fromGenerated = await (await getGeneratedPage({ projectId, pageId }))?.getPageConfig?.();
          if (fromGenerated) {
            page = fromGenerated;
          } else if (this.isRuntime) {
            // 运行时使用 vuex 缓存
            page = await this.store.dispatch('runtime/getPageDetail', { projectId, pageId, env });
          } else {
            if (this.type === 'pagelet') {
              // 使用页面内的页面片
              page = this.w?.pageInfo;
            } else {
              // 使用其他页面 OR 页面片 OR 流程引擎的节点页面片
              page = await pageLoader.getPageDetail({ projectId, pageId });
            }
          }
        }
        this.config = page;
      } catch (e) {
        console.error('[xy-page-container].initPage', e);
      }
    },
  },
};
</script>

<style lang="scss" scope>
.xy-page-container {
  position: relative;
  .xy-page-container-tips {
    min-height: 150px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(0, 0, 0, 0.05);
    border: 1px dotted rgba(0, 0, 0, 0.6);
  }
}
</style>
