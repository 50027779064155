
import wujiFetch from '@/utils/wujiFetch';
import { workflowEntityApi } from '@/services';

export const getWorkflow = async (projectId, appId, schemaId) => {
  const query = new URLSearchParams({
    appid: appId,
    schemaid: schemaId,
    projectid: projectId,
  });

  return wujiFetch(`/api/xy/access/workflow?${query.toString()}`);
};

const doStart = async (projectId, appId, schemaId, objectId, env) => {
  const query = new URLSearchParams({
    appid: appId,
    schemaid: schemaId,
    projectid: projectId,
    env,
  });

  return wujiFetch(`/api/xy/access/workflow/start?${query.toString()}`, {
    method: 'POST',
    body: JSON.stringify({ _id: objectId }),
  });
};

// deprecated
const start = async (ctx, params) => {
  const { sourceId, objectId } = params;
  const { renderer } = ctx;
  const sourceController = renderer.sources[sourceId];

  if (!objectId) {
    const msg = '创建的数据 id 无效';
    throw new Error(msg);
  }

  const { wContext: { config: { projectId }, env } } = renderer;
  const { config: { mainTable } } = sourceController;
  const [appId, schemaId] = mainTable.split('.');
  const workflow = await getWorkflow(projectId, appId, schemaId);

  if (!workflow) {
    const msg = '没有找到对应的电子流配置';
    throw new Error(msg);
  };

  await doStart(projectId, appId, schemaId, objectId, env);
};

const startWithId = async (projectId, env, workflowId, data) => {
  const result = await workflowEntityApi.start(projectId, env, workflowId, data);
  return result;
};

const transitionWithId = async (projectId, env, entityId, { currentStatus, operation, comment, payload, extra }) => {
  const data = {
    currentStatus,
    operation,
    comment,
    payload,
    extra: extra || null,
  };
  const result = await workflowEntityApi.transition(projectId, env, entityId, data);
  return result;
};

const doTransition = async ({ projectId, appId, schemaId, objectId, env }, data) => {
  const query = new URLSearchParams({
    appid: appId,
    schemaid: schemaId,
    projectid: projectId,
    env,
  });
  return wujiFetch(`/api/xy/access/workflow/transition?${query.toString()}`, {
    method: 'POST',
    body: JSON.stringify({
      ...data,
      _id: objectId,
    }),
  });
}
;

// deprecated
const transition = async (ctx, params, operationResult) => {
  const { wContext: { workflowEntity, env, config: { projectId } } } = ctx.renderer;
  const { sourceId, comment = '' } = params;
  if (workflowEntity) {
    const { projectId, appId, schemaId, objectId, status } = workflowEntity;
    const data = {
      currentNodeId: status,
      result: operationResult,
      comment,
    };
    const result = await doTransition({ projectId, appId, schemaId, objectId, env }, data);
    return result;
  }

  // 先保留兼容
  const sourceController = ctx.renderer.sources[sourceId];
  if (sourceController.isCreate) return;

  const { config: { mainTable }, objectId } = sourceController;
  const [appId, schemaId] = mainTable.split('.');
  const workflow = await getWorkflow(projectId, appId, schemaId);

  if (!workflow) return;
  const statusField = workflow.statusField || '_wuji_wf_status';
  const field = [appId, schemaId, statusField].join('$');
  const currentStatus = sourceController.data[field];

  const data = {
    currentNodeId: currentStatus,
    result: operationResult,
    comment,
  };
  const result = await doTransition({ projectId, appId, schemaId, objectId, env }, data);
  return result;
};

export default {
  start,
  transition,
  startWithId,
  transitionWithId,
};
