import TDesignSvg from '@assets/Tdesign.svg';
import AntdSvg from '@assets/antd/antdv.svg';


export const UILibrary = {
  antd: 'antd',
  tdesign: 'tdesign',
};

export const UILibraryIcon = {
  [UILibrary.tdesign]: TDesignSvg,
  [UILibrary.antd]: AntdSvg,
};

export const UILibraryLabel = {
  [UILibrary.tdesign]: 'TDesign',
  [UILibrary.antd]: 'Ant Design Vue',
};
