import { message } from 'ant-design-vue';
import { mergePage } from '@/utils/merge/merge-multi';
import pageLoader from '@loaders/page/loader';
import day from 'dayjs';
import PageService from '@/services/page';


/**
 * 修改页面的上下线状态
 *
 * @param {{ isOffline: any; projectId: any; pageId: any; }} [{ isOffline, projectId, pageId }={}]
 * @returns {*}
 */
const updatePageStatus = ({ isOffline, projectId, pageId } = {}) => {
  if (typeof isOffline !== 'boolean' || !projectId || !pageId) throw new Error('页面操作请求参数错误');
  return PageService.updatePage({ data: { isOffline }, projectId, pageId });
};

export default {
  state: {
    pageInfo: {},
    pageList: [],
    total: 0,
  },
  actions: {
    // 获取编辑时的页面列表
    async fetchBasicPageList({ commit, rootState }, { projectId, query, debugQuery, env, branchId }) {
      try {
        const proId = projectId || rootState.project.projectInfo?.id;
        const data = await pageLoader.loadBasicList(proId, {
          query,
          debugQuery,
        }, env, branchId);
        commit('updatePageState', {
          pageList: data,
        });
        return data;
      } catch (err) {
        console.error(err);
        message.error('获取页面列表失败');
      }
    },
    // 创建页面
    async createPage({ rootState }, data) {
      const projectId = rootState.project?.projectInfo?.id || data.projectId;
      return await PageService.createPage({ data, projectId });
    },
    // 更新页面
    async updatePage({ dispatch }, { remotePage, localPage }) {
      const { pageId, projectId } = localPage;
      const result = await PageService.updatePage({ data: localPage, projectId, pageId });
      dispatch('savePageRecord', { projectId, local: localPage, remote: remotePage });
      return result;
    },
    // 更新页面(校验合并)
    async mergeAndUpdatePage({ dispatch }, { localPage, basePage }) {
      const { mtime: localMtime, pageId, projectId } = localPage;

      // 合并与校验冲突
      const remotePage = await pageLoader.getPageDetail({
        projectId,
        pageId,
      });
      const { mtime: remoteMtime } = remotePage;

      let data = localPage;
      let merged = false;
      if (day(remoteMtime).isAfter(localMtime)) {
        const result = await mergePage({ local: localPage, base: basePage, remote: remotePage });
        if (result.success) {
          merged = true;
          data = result.data;
        } else {
          throw new Error('合并页面修改冲突失败, 取消保存');
        }
      }

      const result = await PageService.updatePage({ data, projectId, pageId: data.pageId });

      dispatch('savePageRecord', { projectId, local: data, remote: remotePage });

      return {
        merged,
        result,
      };
    },
    // 获取页面详情
    async fetchPageDetail({ rootState, commit }, { projectId, pageId } = {}) {
      try {
        const proId = rootState.project?.projectInfo?.id || projectId;
        const data = await pageLoader.getPageDetail({
          projectId: proId,
          pageId,
        });
        if (data) {
          // 处理被删除的字段
          ['status'].forEach((item) => {
            if (Object.prototype.hasOwnProperty.call(data, item)) {
              delete data[item];
            }
          });
          commit('updatePageState', {
            pageInfo: data,
          });
        }
        return data;
      } catch (e) {
        console.error(e);
        message.error(`拉取页面数据失败：${e.message}`);
        throw e;
      }
    },
    // 删除页面
    async deletePage(opt, { projectId, pageId }) {
      return await PageService.deletePage({ projectId, pageId });
    },
    // 上线页面
    async onlinePage(opt, { projectId, pageId }) {
      const result = await updatePageStatus({ isOffline: false, projectId, pageId });
      return result;
    },
    // 下线页面
    async offlinePage(opt, { projectId, pageId }) {
      const result = await updatePageStatus({ isOffline: true, projectId, pageId });
      return result;
    },
  },
  mutations: {
    updatePageState(state, payload) {
      Object.assign(state, payload);
    },
  },
};
