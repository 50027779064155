<template>
  <div class="wrapper">
    <div class="header-container">
      <div class="title">
        <a-icon
          type="arrow-left"
          class="breadcrumb-icon"
          @click="goBack"
        />
        <h3 class="title">
          {{ currentTitle }}
        </h3>
      </div>
    </div>

    <page-layout
      :wrap-styles="{
        padding: '24px',
      }"
      :styles="{
        background: ''
      }"
    >
      <div
        v-if="!isMobileScreen"
        class="search-container"
      >
        <a-form-model
          layout="inline"
          @submit="search"
        >
          <a-form-model-item label="名称">
            <a-input
              v-model="searchForm.name"
              style="width:200px"
            />
          </a-form-model-item>
          <a-form-model-item
            v-if="(menu !== 'created' && type === 'basic') || (type === 'job' && menu === 'all')"
            label="申请人"
          >
            <a-input
              v-model="searchForm.creator"
              style="width:200px"
            />
          </a-form-model-item>
          <a-form-model-item
            v-if="type === 'basic'"
            label="流程配置"
          >
            <a-select
              v-model="searchForm.flowId"
              style="width:200px"
              allow-clear
            >
              <a-select-option
                v-for="workflow of workflows"
                :key="workflow.id"
              >
                {{ workflow.name }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item
            v-if="type === 'basic'"
            label="流程状态"
          >
            <a-select
              v-model="searchForm.status"
              :disabled="menu === 'pending'"
              style="width:200px"
            >
              <a-select-option value="">
                全部
              </a-select-option>
              <a-select-option value="processing">
                审批中
              </a-select-option>
              <a-select-option value="success">
                已通过
              </a-select-option>
              <a-select-option value="fail">
                已驳回
              </a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item>
            <a-button
              type="primary"
              html-type="submit"
              @click="search"
            >
              搜索
            </a-button>
          </a-form-model-item>
        </a-form-model>
      </div>
      <div class="container">
        <div class="head">
          <div v-if="ENABLE_FLOWENGINE">
            <a-radio-group
              v-model="type"
              button-style="solid"
              @change="$router.push({ query: { ...$route.query, type }})"
            >
              <a-radio-button value="basic">
                基础审批
              </a-radio-button>
              <a-radio-button value="job">
                高级审批
              </a-radio-button>
            </a-radio-group>
          </div>
          <div
            v-if="!!rowSelection && type === 'basic' && !isMobileScreen"
            class="batch-operation"
          >
            <a-button
              type="primary"
              :disabled="selectedRows.length === 0 || operating"
              @click="() => confirmToBatchOperate('pass')"
            >
              同意所选({{ selectedRows.length }})
            </a-button>
            <a-button
              type="danger"
              :disabled="selectedRows.length === 0 || operating"
              @click="() => confirmToBatchOperate('reject')"
            >
              驳回所选({{ selectedRows.length }})
            </a-button>
          </div>
        </div>
        <div class="content">
          <a-table
            v-if="type === 'basic'"
            :columns="columns"
            row-key="_id"
            :data-source="entities"
            :loading="loading || operating"
            :pagination="pagination"
            :row-selection="loading ? null : rowSelection"
            :scroll="isMobileScreen ? {x: 1000} : undefined"
            @change="handleTableChange"
          >
            <span
              slot="flowStatus"
              slot-scope="flowStatus"
            >
              <a-badge
                v-if="false"
                :status="flowStatusMap[flowStatus].type"
                :text="flowStatusMap[flowStatus].text"
              />
              <span
                class="dot"
                :style="{backgroundColor: flowStatusMap[flowStatus].color}"
              />
              <span :style="{color: ['abort', 'fail'].includes(flowStatus) ? '#E34D59' : ''}">
                {{ flowStatusMap[flowStatus].text }}
              </span>
            </span>
            <div
              slot="operation"
              slot-scope="text,record"
            >
              <span>
                <router-link
                  :to="detailRoute(record)"
                >
                  查看
                </router-link>
                <a-popover
                  v-if="!!rowSelection"
                  placement="right"
                >
                  <template slot="title">
                    <span>{{ record.name }}</span>
                  </template>
                  <template slot="content">
                    <div>
                      <a-spin v-if="componentLoading" />
                      <public-w-workflow-detail:antd
                        v-else
                        :key="record._id"
                        :hide-record-time-line="true"
                        :hide-title="true"
                        :default-entity="record"
                      />
                    </div>
                  </template>
                  <a-button type="link">预览</a-button>
                </a-popover>
              </span>
            </div>
          </a-table>
          <div v-else>
            <related-job-list
              :type="menu"
              :job-query="jobQuery"
            />
          </div>
        </div>
      </div>
    </page-layout>
  </div>
</template>

<script>
import { message, Modal } from 'ant-design-vue';
import dayjs from 'dayjs';
import { getAppParams } from '@utils/path';
import { workflowEntityApi } from '@/services';
import RelatedJobList from '@pages/flow/jobList/RelatedJobList.tsx';
import { getCompsList } from '@/utils/comps-loader';
import { ENABLE_FLOWENGINE } from '@config/constant';
import { isMobileOrNarrowScreen } from '@utils/screen';
import { APPLY_WORKFLOW_ID } from '@pages/modules/permission/constants';


const columns = [
  {
    title: '名称',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: '当前节点',
    dataIndex: 'status',
    key: 'status',
    customRender: (text, record) => {
      const flowConf = JSON.parse(record.flowConf);
      const node = flowConf.nodes.find(node => node.id === text);
      if (!node && text === 'abort') {
        return '已撤销(abort)';
      }
      return `${node.name}(${text})`;
    },
  },
  {
    title: '流程状态',
    dataIndex: 'flowStatus',
    key: 'flowStatus',
    scopedSlots: { customRender: 'flowStatus' },
  },
  {
    title: '审批人',
    dataIndex: 'handlers',
    key: 'handlers',
    customRender: text => text.split(',').join('、'),
  },
  {
    title: '申请人',
    dataIndex: 'creator',
    key: 'creator',
  },
  {
    title: '申请时间',
    dataIndex: 'ctime',
    key: 'ctime',
    customRender: text => dayjs(text).fromNow(),
  },
  {
    title: '操作',
    scopedSlots: { customRender: 'operation' },
  },
];

const pageSizeKey = 'entity/list/page-size';

export default {
  components: {
    RelatedJobList,
  },
  data() {
    return {
      flowStatusMap: {
        processing: { text: '审批中', color: '#0052D9', type: 'processing' },
        success: { text: '已通过', color: 'rgba(0,0,0,0.25)', type: 'success' },
        fail: { text: '已驳回', color: '#E34D59', type: 'error' },
        abort: { text: '已撤销', color: '#E34D59', type: 'error' },
      },
      searchForm: {
        name: '',
        status: '',
        flowId: '',
        creator: '',
      },
      columns,
      entities: [],
      loading: false,
      workflows: [],
      pagination: {
        current: 1,
        pageSize: 10,
        simple: false,
        showSizeChanger: true,
        pageSizeOptions: ['10', '20', '50'],
        total: 0,
        showTotal: total => `共 ${total} 条数据`,
      },
      type: 'basic',
      jobQuery: { title: '', creator: '' },
      rowSelection: null,
      selectedRows: [],
      operating: false,
      componentLoading: true,
      ENABLE_FLOWENGINE,
    };
  },
  computed: {
    urlParams() {
      return getAppParams() || {};
    },
    projectId() {
      return this.urlParams.projectId;
    },
    pageSizeNS() {
      return `${this.projectId}/${pageSizeKey}`;
    },
    env() {
      return this.urlParams.env || '';
    },
    user() {
      return window.GLOBAL_INFO.rtx;
    },
    menu() {
      const { query } = this.$route;
      return query.menu || 'pending';
    },
    currentTitle() {
      const map = {
        pending: '待办审批',
        created: '我的申请',
        participated: '已办审批',
        related: '相关审批',
        all: '全部审批',
      };
      return map[this.menu];
    },
    isMobileScreen() {
      return isMobileOrNarrowScreen();
    },
  },
  watch: {
    menu: {
      handler(value) {
        if (this.type !== 'basic') return;

        const cache = localStorage.getItem(this.pageSizeNS);
        if (cache) this.pagination.pageSize = +cache;
        if (value === 'pending' && !this.isMobileScreen) {
          this.searchForm.status = 'processing';
          this.rowSelection = {
            fixed: true,
            getCheckboxProps: (record) => {
              const flowConf = JSON.parse(record.flowConf);
              const currentNode = flowConf.nodes.find(x => x.id === record.status);
              return {
                props: {
                  disabled: !!currentNode?.pageId && !currentNode.allowBatchApprove,
                },
              };
            },
            onChange: (_keys, rows) => {
              this.selectedRows = rows;
            },
          };
        } else {
          this.searchForm.status = '';
          this.rowSelection = null;
        }
        this.selectedRows = [];
        this.updateEntities(value);
      },
      immediate: true,
    },
  },
  created() {
    this.initWorkflows();
    getCompsList({ compsKey: ['public-w-workflow-detail:antd'], register: true }).then(() => this.componentLoading = false);
    if (this.$route.query.type === 'job') {
      this.type = 'job';
    }
    this.pagination.simple = this.isMobileScreen;
  },
  methods: {
    async initWorkflows() {
      try {
        const resp = await workflowEntityApi.fetchWorkflows(this.projectId, this.env);
        this.workflows = resp;
        if (!resp.some(x => x.id.includes(`:${APPLY_WORKFLOW_ID}`))) {
          this.workflows.push({ id: `${this.projectId}:${APPLY_WORKFLOW_ID}`, name: '权限申请' });
        }
        if (!resp.some(x => x.id.includes(':general-release-approval'))) {
          this.workflows.push({ id: `${this.projectId}:general-release-approval`, name: '发版审批' });
        }
      } catch (err) {
        const msg = '获取流程配置列表失败';
        message.error(msg);
        console.error(msg, err);
      }
    },
    search(e) {
      e.preventDefault();
      if (this.type === 'basic') {
        this.pagination.current = 1;
        this.updateEntities(this.menu);
      } else {
        this.jobQuery.title = this.searchForm.name || '';
        this.jobQuery.creator = this.searchForm.creator?.toLowerCase() || '';
      }
    },
    async updateEntities(type) {
      this.loading = true;
      const filter = this.getFilter();
      try {
        const [listResp, totalResp] = await Promise.all([
          workflowEntityApi.fetchEntities(
            this.projectId, this.env, type,
            { filter, page: this.pagination.current, size: this.pagination.pageSize },
          ),
          workflowEntityApi.fetchEntities(this.projectId, this.env, type, { filter, count: 1 }),
        ]);
        const result = listResp || [];
        const total = totalResp?.total || 0;
        result.forEach((rec, idx) => {
          result[idx].userData = null;
        });
        this.entities = result;
        this.pagination.total = total || 0;
      } catch (err) {
        const msg = '获取流程实例失败';
        message.error(msg);
        console.error(msg, err);
      }
      this.loading = false;
    },
    async menuClick({ key }) {
      const { query, path } = this.$route;
      this.$router.replace({ path, query: { ...query, menu: key } });
      this.pagination.current = 1;
      await this.updateEntities(key);
    },
    getFilter() {
      const cons = [];
      const { name, status, flowId, creator } = this.searchForm;
      if (name) {
        cons.push(`name%%${name}`);
      }
      if (status) {
        cons.push(`flowStatus="${status}"`);
      }
      if (creator) {
        cons.push(`creator%%${creator}`);
      }
      if (flowId) {
        cons.push(`flowId=${flowId}`);
      }
      return cons.filter(Boolean).join('&');
    },
    async handleTableChange(pagination) {
      this.pagination.current = pagination.current;
      if (this.pagination.pageSize !== pagination.pageSize) {
        this.pagination.pageSize = pagination.pageSize;
        this.pagination.current = 1;
        localStorage.setItem(this.pageSizeNS, pagination.pageSize);
      }
      await this.updateEntities(this.menu);
    },
    goToCreate({ key }) {
      this.$router.push({ name: 'workflow.start', query: { flowid: key } });
    },
    goBack() {
      if (this.menu === 'pending') this.$router.push('/');
      else this.$router.go(-1);
    },
    confirmToBatchOperate(type) {
      if (!this.selectedRows.length) return;
      const text = type === 'pass' ? '同意' : '驳回';
      Modal.confirm({
        title: '批量审批确认',
        content: `确认${text}已选中的 ${this.selectedRows.length} 条流程单？`,
        okText: `确认${text}`,
        okType: type === 'pass' ? 'primary' : 'danger',
        cancelText: '取消',
        onOk: async () => {
          await this.batchOperation(type);
        },
      });
    },
    async batchOperation(type) {
      this.operating = true;
      try {
        const promises = this.selectedRows.map((entity) => {
          const { _id: id, status } = entity;
          return workflowEntityApi.transition(this.projectId, this.env, id, {
            currentStatus: status,
            operation: type,
          });
        });
        await Promise.all(promises);
        this.selectedRows = [];
        message.success('批量审批成功');
        this.updateEntities(this.menu);
      } catch (error) {
        message.error('批量审批失败');
        console.error('batch approval operation failed', error);
      }
      this.operating = false;
    },
    detailRoute(record) {
      const { _id: entityId, objectId } = record;
      return {
        name: 'workflow.entity-detail',
        query: { id: objectId, entityid: entityId },
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.wrapper {
  display: flex;
  flex-direction: column;
  flex: 1;
  min-height: 100%;
}

.header-container {
  height: 56px;
  background: #FFFFFF;
  box-shadow: inset 0 -1px 0 0 #EEEEEE;
  border-left: 1px solid #F0F2F5;

  .title {
    display: flex;
    align-items: center;
    height: 56px;
    padding-left: 24px;
    padding-right: 24px;

    h3 {
      font-size: 20px;
      color: rgba(0,0,0,0.90);
      line-height: 28px;
      margin: 0;
      padding-left: 12px;
    }
  }
}

.search-container {
  background-color: #FFF;
  margin-bottom: 16px;
  padding: 24px;
}

.container {
  padding: 24px;
  border-radius: 4px;
  background-color: #FFF;
}

.head {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.grid {
  display: grid;
  grid-template-columns: 300px auto;
}

.content {
  margin-top: 16px;
}

.dot {
  display: inline-block;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  vertical-align: middle;
}

.label-value {
  display: grid;
  grid-template-columns: 160px auto;
  gap: 8px;

  .label {
    justify-self: end;
  }
}
</style>
