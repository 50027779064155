import { set, take, cloneDeep } from 'lodash';
import qs from 'query-string';
import { stringInteropRouteConfig, isRegexPath, getPathCompiler, getPathMatcher, getWithoutNilQuery } from '@utils/path';
import { checkPermission } from '@pages/modules/permission/utils';
import { PAGE_PERMISSIONS } from '@pages/modules/permission/constants';
import { isRuntime } from '@/utils/globalInfo';
import { useStore } from '@store/index';
import logger from '@utils/logger';

function isEmptyGroup(group) {
  return !(Array.isArray(group.menus) && group.menus.length > 0);
}

// 是否有子菜单
export function haveSubMenu(menu) {
  return menu.type === 'menu' && Array.isArray(menu?.args?.menus) && menu.args.menus.length > 0;
}

// 获取子菜单
export function getSubMenu(menu) {
  return menu?.args?.menus ?? [];
}

// 获取菜单URL
export function getMenuUrl(menu) {
  return menu?.args?.url || '';
}

// 获取菜单的 query 参数
export function getMenuParams(menu) {
  return menu?.args?.params || '';
}

// 获取菜单的 params 参数
export function getMenuPathParams(menu) {
  return menu?.args?.pathParams || '';
}

// 获取菜单的URL + 参数
export function getMenuUrlAndParams(menu) {
  return `${menu?.args?.url || ''}${menu?.args?.params || ''}`;
}

// 获取菜单的关联页面 Ids
export function getMenuLinkPages(menu) {
  if (!isMenuLink) return [];
  return menu?.args?.linkPages ?? [];
}

export function isMenuLink(menu) {
  return menu.type === 'link';
}

// 获取多级菜单的 key
export function getMenuKey(menus = []) {
  let key = getMenuChainName(menus);

  const linkMenu = menus[menus.length - 1];
  const menuParams = getMenuParams(linkMenu);
  if (linkMenu?.type === 'link' && menuParams) {
    key += menuParams;
  }

  return key;
}

// 获取多级菜单的 name
export function getMenuChainName(menus = []) {
  return menus.map(m => m.name).join('.');
}

// 计算普通路径匹配的权重
function computedNormalPathPriority({ linkPages, path, query, currentPageId, currentPath, currentQuery }) {
  try {
    // 如果当前页面路径不匹配，但是页面 id 为该菜单项中的关联页面，权重为 1（权重最低）
    const miniumPriority = linkPages.length && linkPages.includes(currentPageId) ? 1 : 0;

    const queryString = qs.stringify(query);
    if (path !== currentPath) {
      return miniumPriority;
    }

    let priority = 10000;
    if (!queryString && path === currentPath) return priority;

    const queryPairs = Object.entries(query);
    for (let i = 0; i < queryPairs.length; i++) {
      const [key, value] = queryPairs[i];
      if (currentQuery[key] === value) {
        priority += 10;
      } else {
        return miniumPriority;
      }
    }

    return priority;
  } catch (err) {
    logger.log('[Sidebar].computedNormalPathPriority', err);
    return 0;
  }
}

// 计算正则匹配的权重
function computedRegexPathPriority({ linkPages, path, query, params, currentPageId, currentPath, currentQuery }) {
  try {
    const matchRegexPath = getPathMatcher(path, { end: false });
    const matchRes = matchRegexPath(currentPath);

    // 如果当前页面路径不匹配，但是页面 id 为该菜单项中的关联页面，权重为 1（权重最低）
    const miniumPriority = linkPages.length && linkPages.includes(currentPageId) ? 1 : 0;

    const paramsString = qs.stringify(params);
    if (!matchRes) {
      return miniumPriority;
    }

    let priority = 1000;
    if (paramsString) {
      const compiler = getPathCompiler(path);
      const compilePath = compiler(params, { pretty: true });
      if (currentPath !== compilePath) {
        return miniumPriority;
      }
      const paramsLength = Object.keys(params).length;
      priority += (100 * paramsLength);
    }

    const queryPairs = Object.entries(query);
    for (let i = 0; i < queryPairs.length; i++) {
      const [key, value] = queryPairs[i];
      if (currentQuery[key] === value) {
        priority += 10;
      } else {
        return miniumPriority;
      }
    }

    return priority;
  } catch (err) {
    logger.log('[Sidebar].computedRegexPathPriority', err);
    return 0;
  }
}

// 计算菜单项配置的匹配权重
function computedMenuPriority(menu, { $route, stringInteropContext }) {
  const currentPageId = $route?.meta?.pageId;
  const currentPath = $route?.path ?? '';
  const currentQuery = $route?.query ?? {};
  let priority = 0;

  const { path, query: queryString, params: paramsString } = stringInteropRouteConfig({
    path: getMenuUrl(menu),
    params: getMenuPathParams(menu),
    query: getMenuParams(menu),
    stringInteropContext,
  });

  const query = getWithoutNilQuery(qs.parse(queryString));
  const params = qs.parse(paramsString);

  const linkPages = getMenuLinkPages(menu);
  if (isRegexPath(path)) {
    priority = computedRegexPathPriority({ linkPages, path, query, params, currentPageId, currentPath, currentQuery });
  } else {
    priority = computedNormalPathPriority({ linkPages, path, query, currentPageId, currentPath, currentQuery });
  }

  return priority;
}

const getParentMenusOpenKeys = (parentMenus = []) => {
  const keys = [];
  if (!parentMenus.length) return keys;

  for (let i = 0; i < parentMenus.length; i++) {
    keys.push(getMenuKey(take(parentMenus, i + 1)));
  }
  return keys;
};

export function getMatchedMenuKeys(menus, options) {
  const menuSelectedPriority = {};

  const addMenuSelectedPriority = ({ priority, menu, parentMenus, openKeys, selectedKeys }) =>  {
    if (!menuSelectedPriority[priority]) {
      menuSelectedPriority[priority] = {
        menu,
        parentMenus,
        openKeys,
        selectedKeys,
      };
    }
  };

  const collectMenuPriority = (subMenus, parentMenus = []) => {
    subMenus?.forEach((menu) => {
      const nextParentMenus = [...parentMenus, menu];
      if (haveSubMenu(menu)) {
        // 有子菜单
        collectMenuPriority(getSubMenu(menu), nextParentMenus);
      } else {
        // 无子菜单
        const priority = computedMenuPriority(menu, options);
        if (priority > 0) {
          const isLinkPage = priority === 1;
          if (isLinkPage) {
            addMenuSelectedPriority({
              priority,
              menu: { name: options?.$route?.meta?.pageName },
              parentMenus: [...parentMenus, menu],
              openKeys: getParentMenusOpenKeys(parentMenus),
              selectedKeys: [getMenuKey(nextParentMenus)],
            });
          } else {
            addMenuSelectedPriority({
              priority,
              menu,
              parentMenus,
              openKeys: getParentMenusOpenKeys(parentMenus),
              selectedKeys: [getMenuKey(nextParentMenus)],
            });
          }
        }
      }
    });
  };

  collectMenuPriority(menus);

  const priorities = Object.keys(menuSelectedPriority).map(p => +p);
  const maxPriority = Math.max(...priorities);

  const openKeys = menuSelectedPriority?.[maxPriority]?.openKeys ?? [];
  const selectedKeys =  menuSelectedPriority?.[maxPriority]?.selectedKeys ?? [];
  const activeMenu = menuSelectedPriority?.[maxPriority]?.menu ?? null;
  const parentMenus = menuSelectedPriority?.[maxPriority]?.parentMenus ?? [];
  const matchedMenus = Object.keys(menuSelectedPriority).filter(key => Number(key) >= 1000)
    .reduce((pre, cur) => {
      const curMenu = menuSelectedPriority[cur]?.menu;
      if (curMenu) pre.push(curMenu);
      return pre;
    }, []);

  return {
    matchedMenus,
    activeMenu,
    parentMenus,
    openKeys,
    selectedKeys,
  };
}

// 获取匹配的菜单分组Id
export function getMatchedGroupId(groups, options) {
  let currentPriority = 0;
  let currentGroup = '';

  const computeMenus = (groupId, subMenus) => {
    subMenus?.forEach((menu) => {
      if (haveSubMenu(menu)) {
        computeMenus(groupId, getSubMenu(menu));
      } else {
        const priority = computedMenuPriority(menu, options);
        if (priority > currentPriority) {
          currentPriority = priority;
          currentGroup = groupId;
        }
      }
    });
  };

  // 根据 path + query 计算权重
  groups.forEach((group) => {
    if (!isEmptyGroup(group)) {
      computeMenus(group.groupId, group.menus);
    }
  });

  // 路径没有命中菜单组时查找页面关联的菜单组
  if (!currentGroup) {
    const pageId = options?.$route?.meta.pageId;
    if (pageId) {
      const computeMenusLinkPages = (groupId, subMenus) => {
        for (const menu of subMenus) {
          if (haveSubMenu(menu)) {
            computeMenusLinkPages(groupId, getSubMenu(menu));
          } else if (menu?.args?.linkPages?.includes(pageId)) {
            currentGroup = groupId;
            break;
          }
        }
      };
      for (const group of groups) {
        if (currentGroup) break;
        if (!isEmptyGroup(group)) {
          computeMenusLinkPages(group.groupId, group.menus);
        }
      }
    }
  }

  return currentGroup || '';
}

// 获取菜单分组第一个页面菜单
export function getGroupFirstLinkMenu(group) {
  const getFirstLinkMenu = (menu) => {
    if (isMenuLink(menu)) return menu;

    if (haveSubMenu(menu)) {
      const subMenus = getSubMenu(menu);
      for (let i = 0; i < subMenus.length; i++) {
        const subMenu = subMenus[i];
        const firstLinkMenu = getFirstLinkMenu(subMenu);
        if (firstLinkMenu) return firstLinkMenu;
      }
    }

    return null;
  };

  if (!isEmptyGroup(group)) {
    for (let i = 0; i < group.menus.length; i++) {
      const subMenu = group.menus[i];
      const firstLinkMenu = getFirstLinkMenu(subMenu);
      if (firstLinkMenu) return firstLinkMenu;
    }
  }

  return null;
}

// 获取所有需要打开的 keys
export function getAllOpenKeys(menus) {
  const openKeys = [];

  const collectOpenKeys = (subMenus, parentMenus = []) => {
    subMenus?.forEach((menu) => {
      if (haveSubMenu(menu)) {
        const nextParentMenus = [...parentMenus, menu];
        openKeys.push(nextParentMenus.map(i => i.name).join('.'));
        collectOpenKeys(getSubMenu(menu), nextParentMenus);
      }
    });
  };

  collectOpenKeys(menus);

  return openKeys;
}

// 获取配置了需要打开的 keys
export function getConfigOpenKeys(menus) {
  const openKeys = [];

  const collectOpenKeys = (subMenus, parentMenus = []) => {
    subMenus?.forEach((menu) => {
      if (haveSubMenu(menu)) {
        const nextParentMenus = [...parentMenus, menu];
        if (menu?.args?.open) openKeys.push(nextParentMenus.map(i => i.name).join('.'));
        collectOpenKeys(getSubMenu(menu), nextParentMenus);
      }
    });
  };

  collectOpenKeys(menus);

  return openKeys;
}

/* 判断用户 + 页面的权限 */
export function isPermissionMatched(menu, roles, isAdmin) {
  // 预览为所有角色时不判断权限
  if (roles?.[0]?.role === '*') return true;

  // 非链接的菜单项不判断权限
  if (!isMenuLink(menu)) return true;

  const { permissions: menuPermission = ['*'], urlType, url } = menu?.args ?? {};

  let pageId;
  if (urlType === 'page') {
    let pages = [];
    if (isRuntime()) {
      pages = window?.xy_runtime_pages ?? [];
    } else {
      pages = useStore()?.state?.page?.pageList ?? [];
    }
    const page = pages.find(({ path }) => path === url);
    if (page) pageId = page.pageId;
  }

  if (!Array.isArray(menuPermission) || menuPermission.length === 0) return false;
  if (menuPermission.includes('*')) return true;

  return menuPermission.some((permission) => {
    // 判断页面权限时校验页面 id
    if (PAGE_PERMISSIONS.includes(permission) && !pageId) return false;
    return checkPermission({ roles, isAdmin }, permission, pageId);
  });
}

/* 判断角色是否匹配菜单的角色可见性 */
export function isRoleMatched(menuRoles = ['*'], roles = [], isAdmin = false) {
  if (Array.isArray(menuRoles) && Array.isArray(roles)) {
    return menuRoles.includes('*')
      || roles.includes('*')
      || roles.some(role => menuRoles.includes(role))
      || (isAdmin && menuRoles.some(role => role === 'project.admin'));
  }
  return false;
}

/* 判断业务是否匹配菜单的业务可见性 */
export function isBizMatched(bizes = ['*'], biz = '') {
  if (Array.isArray(bizes)) {
    return bizes.includes('*') || biz === '*' || bizes.includes(biz);
  }
  return false;
}

// 根据角色 + 业务获取菜单数据
export function getVisibleMatchedGroups(groups, { roles = [{ permissions: [], role: '*' }], biz = '*', isAdmin = false } = {}) {
  const cloneGroups = cloneDeep(groups);
  const rolesIds = roles.map(r => r.role);

  const isShowMenu = (menu) => {
    const isShow = haveSubMenu(menu)
      || (
        isMenuLink(menu)
        && isPermissionMatched(menu, roles, isAdmin)
        && isRoleMatched(menu?.args?.roles, rolesIds, isAdmin)
        && isBizMatched(menu?.args?.bizes, biz)
      );
    return isShow;
  };

  const filterMatchedMenu = (menu) => {
    if (haveSubMenu(menu)) {
      getSubMenu(menu).forEach(m => filterMatchedMenu(m));
      set(menu, ['args', 'menus'], menu?.args?.menus?.filter(m => isShowMenu(m)) ?? []);
    }
  };

  cloneGroups.forEach((group) => {
    if (!isEmptyGroup(group)) {
      group?.menus?.forEach(menu => filterMatchedMenu(menu));
      set(group, ['menus'], group?.menus?.filter((m => isShowMenu(m))) ?? []);
    }
  });

  // 过滤没有菜单项的菜单组
  return cloneGroups.filter(group => group.menus.length > 0);
}

// 根据关键词搜索菜单
export function filterSearchMenus(menus, keywords) {
  if (!keywords) return menus;
  const filterMenus = menus => menus.filter((menu) => {
    if (haveSubMenu(menu)) {
      set(menu, ['args', 'menus'], filterMenus(menu?.args?.menus ?? []));
      return menu.args.menus.length > 0;
    }
    return menu.name.toLowerCase().includes(keywords.toLowerCase());
  });

  return filterMenus(cloneDeep(menus));
}

export function getGroupNameMap(groups) {
  const groupMap = {};

  const getMenuMap = (menuMap, subMenus, parentMenus = []) => {
    subMenus?.forEach((menu) => {
      const nextParentMenus = [...parentMenus, menu];
      const key = getMenuChainName(nextParentMenus);
      if (haveSubMenu(menu)) {
        set(menuMap, [key], { menu });
        getMenuMap(menuMap, getSubMenu(menu), nextParentMenus);
      } else {
        set(menuMap, [key], {
          menu,
          openKeys: getParentMenusOpenKeys(parentMenus),
          selectedKeys: [getMenuKey(nextParentMenus)],
        });
      }
    });
  };

  groups.forEach((group) => {
    const menuMap = {};
    getMenuMap(menuMap, group.menus);
    groupMap[group.groupName] = menuMap;
  });

  return groupMap;
}

// 校验当前路径是否为不可见的菜单项配置
export function checkBizMenusVisibility({
  biz,
  groups,
  $router,
  $route,
  stringInteropContext = {},
}) {
  if (!biz || biz === '*') return;
  try {
    if (groups.length > 0) {
      const matchedMenus = groups.reduce((pre, cur) => {
        if (cur?.menus?.length > 0) {
          const { matchedMenus } = getMatchedMenuKeys(cur.menus, { $route, stringInteropContext });
          pre.push(...matchedMenus);
        }
        return pre;
      }, []);

      if (matchedMenus.length > 0) {
        const withMatchMenus = matchedMenus.some(menu => isBizMatched(menu?.args?.bizes, biz));

        // 有匹配的菜单项且所有都为不可见的情况下, 跳转首页
        if (!withMatchMenus) {
          $router.replace({ path: '/' });
        }
      }
    }
  } catch (err) {
    logger.log('[Sidebar].checkBizMenusVisibility', err);
  }
}

export default {
  isRoleMatched,
  isBizMatched,
  haveSubMenu,
  getSubMenu,
  getMenuUrl,
  getMatchedGroupId,
  getAllOpenKeys,
  getVisibleMatchedGroups,
};
