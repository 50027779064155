import qs from 'query-string';
import { BASE_PATH, MICRO_APP_BASE_ROUTE, MICRO_APP_ORIGIN, } from '@config/constant';
import { getWithoutNilQuery } from '@/utils/path';
import { useRouter } from '@/router/useRouter';
const pageEditorRouteName = 'pageConfig';
function isEditorApp() {
    const { router } = useRouter();
    const matched = router.value?.match({ name: pageEditorRouteName })?.matched;
    if (Array.isArray(matched))
        return matched.length > 0;
    return false;
}
function runInEditorApp(query = {}, newWindow = false) {
    const { router, route } = useRouter();
    const routeQuery = getWithoutNilQuery({
        ...query,
        pageid: query.pageid,
        projectid: query.projectid || route.value?.query?.projectid,
        branch: query.branch || route.value?.query?.branch,
    });
    const url = router.value?.resolve({ name: pageEditorRouteName, query: routeQuery })?.href;
    if (newWindow) {
        window.open(url, '_blank', 'noopener=yes');
    }
    else {
        window.location.href = url;
    }
}
function runInRuntimeApp(query = {}, newWindow = false) {
    const routeQuery = {
        ...query,
        pageid: query.pageid || window.RUNTIME_CONFIGS?.pageId,
        projectid: query.projectid || window.RUNTIME_CONFIGS?.projectId,
        branch: query.branch || window.RUNTIME_CONFIGS?.branch,
    };
    const queryString = qs.stringify(routeQuery, { skipEmptyString: true, skipNull: true });
    let url = `${BASE_PATH}project/pageconfig`;
    if (queryString)
        url += `?${queryString}`;
    if (MICRO_APP_ORIGIN) {
        url = `${MICRO_APP_ORIGIN}${url.replace(MICRO_APP_BASE_ROUTE, '')}`;
    }
    if (newWindow) {
        window.open(url, '_blank', 'noopener=yes');
    }
    else {
        window.location.href = url;
    }
}
/**
 * 跳转或打开页面编辑器
 */
export function goToPageEditor(query = {}, newWindow = false) {
    if (isEditorApp()) {
        runInEditorApp(query, newWindow);
    }
    else {
        runInRuntimeApp(query, newWindow);
    }
}
/**
 * 新窗口打开页面编辑器
 */
export function openPageEditor(query = {}) {
    goToPageEditor(query, true);
}
