import { set } from 'lodash';
import { defineComponent, ref, computed, watch } from '@vue/composition-api';
import PermissionCheckbox from './PermissionCheckbox';
import { usePagePermissionList, usePermission } from './utils';
import { CREATE_PAGE, DELETE_PAGE, pagePermissionTooltip, PAGE_PERMISSIONS, READ_PAGE, UPDATE_PAGE, UPLOAD_PAGE } from './constants';
import PageMenuPerm from './PageMenuPerm';
import styles from './permission.module.scss';
import { injectPermState } from './PermState';
export default defineComponent({
    name: 'GeneralPermission',
    model: {
        prop: 'rolePerms',
        event: 'change',
    },
    emits: ['change'],
    props: {
        projectId: {
            type: String,
            default: '',
            required: true,
        },
        env: {
            type: String,
            default: 'dev',
        },
        role: {
            type: String,
            default: '',
            required: true,
        },
        tagList: {
            type: Array,
            required: true,
        },
        pages: {
            type: Array,
            required: true,
        },
        rolePerms: {
            type: Array,
            required: true,
        },
        maxHeight: {
            type: String,
            default: '',
        },
        readonly: {
            type: Boolean,
            default: false,
        },
    },
    setup(props) {
        const keyword = ref('');
        // 当页面数量太多时，不支持批量更新, 权限数量 >= 页面数量 * 5
        // 1. 权限最小使用原则，批量勾选易放大权限
        // 2. 更新性能问题
        const maxPage = +(localStorage.getItem('rbac/role-perm/max-page') || 80);
        const tooManyPages = ref(props.pages.length > maxPage);
        const viewModeKey = `${props.projectId}/rbac/role-perm/view-mode`;
        const viewMode = ref(tooManyPages.value ? 'page' : 'default');
        if (localStorage.getItem(viewModeKey))
            viewMode.value = localStorage.getItem(viewModeKey);
        const selectedTags = ref([]);
        const rowCheck = ref({});
        const checkAll = ref(false);
        const checkAllLoading = ref(false);
        const { pagePermissionList, fetchPagePermissionList, isPageRelatedPermission } = usePagePermissionList(props.projectId);
        const { checkProject } = usePermission(props.projectId);
        const checkMap = ref({});
        const checkProjectMap = ref({
            [READ_PAGE]: false,
            [CREATE_PAGE]: false,
            [UPDATE_PAGE]: false,
            [DELETE_PAGE]: false,
            [UPLOAD_PAGE]: false,
        });
        const current = ref(1);
        const pageSize = ref(+(localStorage.getItem('rbac/role-perm/page-size') || 20));
        const pagination = computed(() => ({
            current: current.value,
            pageSize: pageSize.value,
            size: 'small',
            pageSizeOptions: ['20', '50', '100', '200'],
            total: filterPages.value.length,
            onCurrentChange: (cur) => {
                current.value = cur;
            },
            onPageSizeChange: (size) => {
                current.value = 1;
                pageSize.value = size;
                localStorage.setItem('rbac/role-perm/page-size', String(size));
            },
        }));
        watch(() => [keyword.value, selectedTags.value], () => current.value = 1, {
            deep: true,
        });
        const filterPages = computed(() => {
            const q = keyword.value.toLowerCase();
            let pages = [...props.pages];
            pages = pages.filter(p => p.pageName.toLowerCase().includes(q) || p.pageId.toLowerCase().includes(q));
            pages = pages.filter(p => (selectedTags.value.length
                ? selectedTags.value.some(tag => p.tags.some(pt => tag.includes(pt)))
                : true));
            return pages;
        });
        const pageData = computed(() => {
            if (filterPages.value.length <= maxPage)
                return filterPages.value;
            const start = (current.value - 1) * pageSize.value;
            return filterPages.value.slice(start, start + pageSize.value);
        });
        const rolePermsMap = ref({});
        const permState = injectPermState();
        const init = (propRolePerms) => {
            let allRowChecked = true;
            rolePermsMap.value = {};
            propRolePerms.forEach((x) => {
                rolePermsMap.value[`${x.pageId}/${x.permission}`] = true;
            });
            filterPages.value.forEach((p) => {
                const rolePerms = propRolePerms.filter(r => r.pageId === p.pageId);
                const filterRelatedPagePerms = pagePermissionList.value.filter(x => isPageRelatedPermission(x.name, p.pageId));
                const rowChecked = filterRelatedPagePerms.every(perm => rolePerms.some(r => r.permission === perm.name)
                    || checkProject(propRolePerms, props.role, perm.name));
                if (!rowChecked)
                    allRowChecked = false;
                rowCheck.value[p.pageId] = rowChecked;
            });
            const columnCheck = (perm) => {
                const filterRelatedPages = filterPages.value.filter(x => isPageRelatedPermission(perm, x.pageId));
                const checked = filterRelatedPages
                    .every(p => propRolePerms.some(r => r.pageId === p.pageId && r.permission === perm));
                return checked;
            };
            let isAllTrue = true;
            pagePermissionList.value.forEach((x) => {
                const ok = columnCheck(x.name);
                if (!ok)
                    isAllTrue = false;
                checkMap.value[x.name] = ok;
            });
            PAGE_PERMISSIONS.forEach((perm) => {
                const projectPerm = perm.replace('.page', '.project');
                checkProjectMap.value[perm] = propRolePerms.some(x => x.permission === projectPerm);
            });
            checkAll.value = allRowChecked && isAllTrue;
            checkAllLoading.value = false;
        };
        const readonlyOrTooManyPages = computed(() => props.readonly || tooManyPages.value);
        return {
            pagination,
            pageData,
            maxPage,
            keyword,
            tooManyPages,
            readonlyOrTooManyPages,
            viewMode,
            viewModeKey,
            selectedTags,
            filterPages,
            rowCheck,
            checkMap,
            checkProjectMap,
            checkAll,
            checkAllLoading,
            isPageRelatedPermission,
            init,
            pagePermissionList,
            fetchPagePermissionList,
            rolePermsMap,
            permState,
        };
    },
    created() {
        this.pages.forEach(p => this.$set(this.rowCheck, p.pageId, false));
        this.fetchPagePermissionList(this.env).then(() => {
            this.pagePermissionList.forEach((x) => {
                this.$set(this.checkMap, x.name, false);
            });
            this.init(this.rolePerms);
        });
    },
    watch: {
        rolePerms: {
            handler() {
                this.init(this.rolePerms);
            },
        },
        filterPages: {
            handler() {
                this.init(this.rolePerms);
            },
        },
    },
    render() {
        const checkChange = (e) => {
            this.checkAllLoading = true;
            if (Array.isArray(e.data)) {
                e.data.forEach((x) => {
                    updateChangePermName(x);
                });
            }
            else {
                updateChangePermName(e.data);
            }
            this.$emit('checkChange', e);
        };
        const renderPagination = () => {
            if (this.filterPages.length <= this.maxPage)
                return null;
            return this.pagination;
        };
        const renderCheck = (permission, pageId) => {
            const isRelatedPage = this.isPageRelatedPermission(permission, pageId);
            return isRelatedPage
                ? <PermissionCheckbox projectId={this.projectId} env={this.env} role={this.role} rolePerms={this.rolePerms} permission={permission} pageId={pageId} readonly={this.readonly} onCheckChange={(e) => checkChange(e)} onChange={(v) => {
                        this.$emit('change', v);
                    }}/>
                : <t-tooltip content="不相关"><span style="margin-left:4px">-</span></t-tooltip>;
        };
        const pageColumns = this.pagePermissionList.map((x) => ({
            slot: x.name,
            name: x.name,
            cname: pagePermissionTooltip[x.name]?.name || x.cname,
            tooltip: pagePermissionTooltip[x.name]?.tooltip || '',
            onChange: (checked) => checkAllColumnChange(x.name, checked),
        }));
        const columns = [
            {
                title: '页面',
                colKey: 'pageName',
                width: 300,
                fixed: 'left',
            },
            ...(this.readonly
                ? []
                : [
                    {
                        width: 100,
                        render: () => <span>全部</span>,
                        cell: (h, { row: record }) => (<t-checkbox checked={this.rowCheck[record.pageId]} disabled={this.checkAllLoading} onChange={(checked) => {
                                // 行变更，单一行选中全部列
                                const rolePerms = [...this.rolePerms].filter(r => r.pageId !== record.pageId);
                                const changes = [];
                                let type = 'checked';
                                if (checked) {
                                    this.pagePermissionList.forEach((x) => {
                                        const key = `${record.pageId}/${x.name}`;
                                        if (this.isPageRelatedPermission(x.name, record.pageId) // 权限与当前页面相关
                                            && !this.checkProjectMap[x.name] // 且对应的应用权限没有选中
                                        ) {
                                            rolePerms.push({ pageId: record.pageId, permission: x.name });
                                            // 权限没有选中过
                                            !this.rolePermsMap[key] && changes.push({ pageId: record.pageId, permission: x.name });
                                        }
                                    });
                                }
                                else {
                                    type = 'unchecked';
                                    const rolePerms = [...this.rolePerms].filter(r => r.pageId === record.pageId);
                                    changes.push(...rolePerms.map(x => ({ pageId: record.pageId, permission: x.permission })));
                                }
                                this.rowCheck[record.pageId] = checked;
                                if (this.permState.panguMappingEnabled.value)
                                    this.$emit('change', rolePerms);
                                else
                                    checkChange({ type, data: changes });
                            }}/>),
                    },
                ]),
            ...(pageColumns.map((x) => {
                const col = {
                    width: 120,
                    cell: (h, { row: record }) => renderCheck(x.name, record.pageId),
                    render: () => <span class="flex items-center">
            {this.readonly || this.filterPages.length === 0 || this.checkProjectMap[x.name]
                            ? (<span>{x.cname}</span>)
                            : (<t-checkbox checked={this.checkMap[x.name]} disabled={this.checkAllLoading} onChange={x.onChange}>
                      {x.cname}
                    </t-checkbox>)}
            {x.tooltip ? <t-tooltip content={x.tooltip}>
                <t-icon class="ml-1" name="info-circle"/>
              </t-tooltip> : null}
          </span>,
                };
                return col;
            })),
        ];
        const checkAllColumnChange = (permission, checked) => {
            // 列头变更事件：单一列选中全部行
            const rolePerms = [...this.rolePerms].filter(r => r.permission !== permission);
            let type = 'checked';
            const changes = [];
            if (checked) {
                this.filterPages.forEach((p) => {
                    this.isPageRelatedPermission(permission, p.pageId) && rolePerms.push({ pageId: p.pageId, permission });
                    const key = `${p.pageId}/${permission}`;
                    if (this.isPageRelatedPermission(permission, p.pageId)
                        && !this.rolePermsMap[key]) {
                        changes.push({ pageId: p.pageId, permission });
                    }
                });
            }
            else {
                type = 'unchecked';
                changes.push(...this.rolePerms
                    .filter(x => x.permission === permission)
                    .filter(x => this.filterPages.some(p => p.pageId === x.pageId))
                    .map(x => ({ permission, pageId: x.pageId })));
            }
            this.checkMap[permission] = checked;
            if (this.permState.panguMappingEnabled.value)
                this.$emit('change', rolePerms);
            else
                checkChange({ type, data: changes });
        };
        const updateChangePermName = (data) => {
            const { permission } = data;
            const pagePerm = this.pagePermissionList.find(x => x.name === permission);
            const pageName = this.pages.find(x => x.pageId === data.pageId)?.pageName;
            if (pagePerm)
                set(data, 'permName', pageName ? `${pageName}-${pagePerm.cname}` : pagePerm.cname);
        };
        const pageListPerm = <div>
      <t-table columns={columns} data={this.pageData} rowKey="id" hover pagination={renderPagination()} maxHeight={this.maxHeight} scroll={{ type: 'virtual' }}>
      </t-table>
    </div>;
        return (<div>
        <div class={styles.generalWrapper}>
          <div class={styles.searchTag}>
            <t-input v-model={this.keyword} placeholder="请输入页面名称或路径进行搜索" style="width:240px">
              <t-icon name="search" slot="suffixIcon"></t-icon>
            </t-input>
            {this.tagList.length ? (<div style="marginLeft:8px;display:flex;alignItems:center">
                <a-select mode="multiple" style="width:120px" placeholder="页面标签筛选" value={[]} onSelect={(v) => this.selectedTags.splice(0, 0, v)}>
                  {this.tagList.filter(tag => !this.selectedTags.includes(`${tag.id}:${tag.name}`))
                    .map(tag => <a-select-option key={tag.id} value={`${tag.id}:${tag.name}`}>{tag.name}</a-select-option>)}
                </a-select>
              </div>) : null}
          </div>
          <div style="width:100%;overflow-x:auto;white-space:nowrap">
            {this.selectedTags.map(tag => <t-tag class="ml-1" key={tag} closable onClose={() => {
                    this.selectedTags = this.selectedTags.filter(t => t !== tag);
                }}>{tag.split(':').pop()}</t-tag>)}
          </div>
          <t-radio-group v-model={this.viewMode} variant="primary-filled" onChange={(val) => localStorage.setItem(this.viewModeKey, val)} style="flex:none">
            <t-radio-button value="default">概览</t-radio-button>
            <t-radio-button value="page">页面视图</t-radio-button>
          </t-radio-group>
        </div>
        {this.viewMode === 'default'
                ? pageListPerm
                : <div style="height:400px">
                <PageMenuPerm projectId={this.projectId} env={this.env} role={this.role} rolePerms={this.rolePerms} readonly={this.readonly} pagePermList={this.pagePermissionList} pageList={this.filterPages} onCheckChange={checkChange} onChange={(v) => this.$emit('change', v)}/>
              </div>}
      </div>);
    },
});
