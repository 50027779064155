<template>
  <div class="nav-drop">
    <a-popover
      v-model="show"
      :overlay-class-name="`nav-drop-popover ${mode}`"
      :trigger="menu.args.trigger"
    >
      <template slot="content">
        <p
          v-for="(item, index) in menu.args.menus"
          v-show="item.isShow !== false"
          :key="index"
          class="nav-drop_options"
          @click="click"
        >
          <NavLinkItem
            :link="item"
            :disabled="disabled"
          />
        </p>
      </template>
      <span class="nav-drop_button">
        <span>{{ menu.name }}</span>
        <a-icon
          type="down"
          :class="['nav-drop_icon', {'nav-drop_icon-active': show}]"
        />
      </span>
    </a-popover>
  </div>
</template>

<script>
import NavLinkItem from './NavLinkItem';

export default {
  components: {
    NavLinkItem,
  },
  props: {
    menu: {
      type: Object,
      default: () => ({}),
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    mode: {
      type: String,
      default: 'light',
    },
  },
  data() {
    return {
      show: false,
    };
  },
  methods: {
    click() {
      this.show = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.nav-drop {
  &_button {
    border-radius: 14px;
    padding: 5px 0;
    cursor: pointer;
  }
  &_icon {
    font-size: 12px;
    transform: rotate(0deg);
    transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    &-active {
      transform: rotate(180deg);
    }
  }

  &_button:hover{
    color: var(--xy-custom-nav-hover-color);
  }
  &_button.ant-popover-open {
    color: var(--xy-custom-nav-active-color);
  }
  &_options {
    margin: 0;
    cursor: pointer;
  }
}
</style>

