var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Getter } from 'vue-class-state';
import { get, set, isEqual } from 'lodash';
import { vueDeepSet } from '@tencent/ui-core/lib/utils';
import { FormPropChild } from './form-prop-child';
import { isNullOrUndefined } from '@tencent/w-flow-common';
export class FormProp {
    key;
    bizId;
    path;
    type;
    format;
    title;
    getFormData;
    getBaseData;
    get changed() {
        return !isEqual(this.value, this.baseValue);
    }
    /**
     * 最外层字段名 config.xxxx.xxx => config
     */
    get rootFieldName() {
        return this.fieldInfo.headName;
    }
    /**
     * 三路合并最后回写的path，包含bizId
     */
    get formDataPath() {
        return `${this.bizId}.${this.path}`;
    }
    get bizData() {
        const formData = this.getFormData();
        return get(formData, this.bizId, undefined);
    }
    get baseValue() {
        const baseData = get(this.getBaseData(), this.bizId, undefined);
        return this.getValue(baseData);
    }
    value;
    get fieldInfo() {
        const { key, path } = this;
        const [headName, ...tailPathArray] = path.split('.');
        if (!headName) {
            throw new Error(`非法path,key=${key}`);
        }
        return {
            headName,
            tailPathArray,
        };
    }
    get lazyValue() {
        const { bizData } = this;
        return this.getValue(bizData);
    }
    constructor(key, bizId, path, type, format, title, getFormData, 
    /**
     * 三路合并的base数据
     */
    getBaseData) {
        this.key = key;
        this.bizId = bizId;
        this.path = path;
        this.type = type;
        this.format = format;
        this.title = title;
        this.getFormData = getFormData;
        this.getBaseData = getBaseData;
        Object.defineProperty(this, 'value', {
            get() {
                return this.lazyValue;
            },
            set(val) {
                const { bizData } = this;
                this.setValue(bizData, val);
            },
        });
    }
    dispose() {
        disposeWatchers(this);
    }
    getValue(bizData) {
        const { path, type } = this;
        if (type === 'jsonString') {
            if (isNullOrUndefined(bizData))
                return undefined;
            const { headName, tailPathArray } = this.fieldInfo;
            const json = typeof bizData[headName] === 'string' ? JSON.parse(bizData[headName]) : bizData[headName];
            return get(json, tailPathArray, undefined);
        }
        return get(bizData, path, undefined);
    }
    setValue(bizData, val) {
        if (this.value === val)
            return;
        const { path, type } = this;
        if (type === 'jsonString') {
            if (isNullOrUndefined(bizData)) {
                return;
            }
            ; // fromData没有这个biz，需要get方法保证返回有值
            const { headName, tailPathArray } = this.fieldInfo;
            const json = JSON.parse(bizData[headName]);
            set(json, tailPathArray, val);
            vueDeepSet(bizData, headName, JSON.stringify(json));
        }
        else {
            vueDeepSet(bizData, path, val);
        }
    }
    /**
     * 但值为空时可用于设置默认值，不触发任何changed变更，可以替代biz的get方法返回默认值
     * 一般在表单初始化时调用
     * @param value
     */
    setValueQuietly(value) {
        const baseData = get(this.getBaseData(), this.bizId, undefined);
        this.setValue(baseData, value);
        this.value = value;
    }
    /**
     *  当前数据源是嵌套的json，判断内部某些字段是否发生了变化
     * @param cb 返回字段值
     * @returns
     */
    hasChanged(cb) {
        const { value, baseValue } = this;
        if (!this.changed) {
            return false;
        }
        return !isEqual(cb(value), cb(baseValue));
    }
    getChildProp(getter) {
        return new FormPropChild(this, getter);
    }
}
__decorate([
    Getter
], FormProp.prototype, "changed", null);
__decorate([
    Getter
], FormProp.prototype, "baseValue", null);
__decorate([
    Getter
], FormProp.prototype, "fieldInfo", null);
__decorate([
    Getter
], FormProp.prototype, "lazyValue", null);
function disposeWatchers(state) {
    const watchers = state?._watchers;
    if (!Array.isArray(watchers)) {
        return;
    }
    let i = watchers.length;
    // eslint-disable-next-line no-plusplus
    while (i--) {
        watchers[i].teardown();
    }
}
