import logger from '@utils/logger';
import { message } from 'ant-design-vue';
import DataTableService from '@/services/dataTable';


export default {
  name: 'datatable',
  state: {
    list: [],
    appList: [],
  },
  getters: {
  },
  actions: {
    /**
    * 获取项目数据表列表
    */
    async fetchDataTables({ commit }, { projectId }) {
      try {
        const list = await DataTableService.fetchDataTables(projectId);
        commit('setDataTable', {
          list,
        });
        return list;
      } catch (e) {
        logger.error(e);
        message.error(`获取数据表列表失败：${e.message}`);
      }
    },
    /**
     * 新增项目数据表
     */
    async addDataTable({}, { projectId, data }) {
      const res = await DataTableService.addDataTable({ projectId, data });
      return res;
    },
    /**
     * 更新项目数据表
     */
    async updateDataTable({ }, { projectId, data }) {
      const res = await DataTableService.updateDataTable({ projectId, data });
      return res;
    },
    /**
     * 删除项目数据表
     */
    async deleteDataTable({ }, { projectId, id }) {
      const res = await DataTableService.deleteDataTable({ projectId, id });
      return res;
    },
    /**
     * 获取已经授权的app和schemas
     */
    async getProjectAppSchemas({ commit }, { projectId }) {
      const result = await DataTableService.getAuthSchemas({ projectId });
      commit('setDataTable', {
        appList: result,
      });
      return result;
    },
  },
  mutations: {
    setDataTable(state, payload) {
      Object.assign(state, payload);
    },
  },
};
