import { message } from 'ant-design-vue';
import wujiFetch from '@utils/wujiFetch';
import { BASE_API_PATH } from '@config/constant';
import qs from 'query-string';
import { utils } from '@tencent/ui-core';
import logger from '@/utils/logger';

const baseUrl = `${BASE_API_PATH}/xy/template`;

export const isPublicTemplate = (templateId) => {
  const regex = /^_xy_public_/;
  return regex.test(templateId);
};

export default {
  name: 'template',
  state: {
    categories: [],
    projectTemplateList: [],
    customProjectTemplateList: [],
    manageCustomTemplateList: [],
    managePublicTemplateList: [],
    publicTemplatePermission: false,
  },
  actions: {
    async fetchTemplateCategory({ commit }) {
      const result = await wujiFetch(
        `${baseUrl}/category`,
        {
          method: 'GET',
        },
      );
      commit('setter', { categories: result });
    },
    async saveProjectTemplate({ }, { type, projectId, ...data }) {
      return await wujiFetch(
        `${baseUrl}/project/${type === 0 ? 'public' : 'custom'}?projectid=${projectId}`,
        {
          method: 'POST',
          body: JSON.stringify({
            projectId,
            version: utils.newestPageletVersion,
            ...data,
          }),
          timeout: 3000,
        },
      );
    },
    async fetchProjectTemplate({ commit }, { filter, type } = { type: 'public' }) {
      try {
        const queryString = qs.stringify({ type, filter });
        let url = `${baseUrl}/project/public`;
        if (queryString) url += `?${queryString}`;
        const result = await wujiFetch(
          url,
          {
            method: 'GET',
          },
        );
        commit('setter', {
          projectTemplateList: result,
        });
        return result;
      } catch (err) {
        message.error(`获取应用模版列表失败${err.message}`);
      }
    },
    async fetchCustomTemplate({ commit }) {
      try {
        const result = await wujiFetch(
          `${baseUrl}/project/custom`,
          {
            method: 'GET',
          },
        );
        commit('setter', {
          customProjectTemplateList: result,
        });
        return result;
      } catch (err) {
        message.error(`获取自定义应用模版列表失败${err.message}`);
      }
    },
    async getProjectTemplate({ commit }, { templateId }) {
      try {
        const isPublic = isPublicTemplate(templateId);
        const result = await wujiFetch(
          `${baseUrl}/project/${isPublic ? 'public' : 'custom'}/${templateId}`,
          {
            method: 'GET',
          },
        );
        commit('setter', {
          detail: result,
        });
        return result;
      } catch (err) {
        message.error(`获取应用模版${templateId}失败:${err.message}`);
      }
    },
    async updateProjectTemplate({ }, { templateId, ...data }) {
      const result = await wujiFetch(`${baseUrl}/project/patch/${templateId}?projectid=${templateId}`, {
        method: 'POST',
        body: JSON.stringify({
          version: utils.newestPageletVersion,
          ...data,
        }),
        timeout: 3000,
      });
      return result;
    },
    async deleteProjectTemplate({ }, { templateId }) {
      const result = await wujiFetch(`${baseUrl}/project/delete/${templateId}`, {
        method: 'POST',
      });
      return result;
    },
    async createProjectByTemplateId({ }, { templateId, id, name, isTemplate, ...rest }) {
      const result = await wujiFetch(
        `${baseUrl}/project/create/${templateId}`,
        {
          method: 'POST',
          body: JSON.stringify({
            id,
            name,
            isTemplate,
            ...rest,
          }),
        },
      );
      return result;
    },
    async fetchManageTemplateList({ commit }, { type }) {
      const url = type === 'public' ? `${baseUrl}/project/public` : `${baseUrl}/project/manage/custom`;
      const result = await wujiFetch(
        url,
        {
          method: 'GET',
        },
      );
      commit('setter', {
        [type === 'public' ? 'managePublicTemplateList' : 'manageCustomTemplateList']: result,
      });
      return result;
    },
    async fetchIsWhiteList({ commit }) {
      const result = await wujiFetch(
        `${baseUrl}/project/iswhitelist`,
        {
          method: 'GET',
        },
      );
      logger.info('fetchIsWhiteList', result);
      commit('setter', {
        publicTemplatePermission: result,
      });
      return result;
    },
  },
  mutations: {
    setter(state, payload) {
      Object.assign(state, payload);
    },
  },
};
