<template>
  <div
    ref="marquee"
    class="marquee"
  >
    <MarqueeText
      v-if="!!anncmntLoopList.length"
      :paused="isPaused"
      :repeat="1"
      :duration="30"
      @mouseenter="isPaused=true"
      @mouseleave="isPaused=false"
    >
      <span ref="wrapWidth" />
      <span
        v-for="(anncmnt,index) in anncmntLoopList"
        :key="index"
        class="anncmnt-span"
        :style="getMarqueeStyle()"
        @click="handlClick(anncmnt)"
      >
        <a-icon
          :type="anncmnt.type === 'warn' ? 'warning' : 'info-circle'"
          :two-tone-color="anncmnt.type === 'warn'? 'red' : '#1890ff'"
          theme="twoTone"
          style="margin-right:2px"
        />
        {{ `${anncmnt.title}: ${anncmnt.content}` }}
      </span>
    </MarqueeText>
  </div>
</template>
<script>
import { mapState, mapActions } from 'vuex';
import MarqueeText from 'vue-marquee-text-component';
import { isEmpty } from 'lodash';

export default ({
  name: 'AnncmntLoop',
  components: {
    MarqueeText,
  },
  props: {
    projectId: {
      type: String,
      default: '',
    },
    isDark: {
      type: Boolean,
      default: false,
    },
    customStyle: {
      type: String,
      default: '{}',
    },
  },
  data() {
    return {
      isPaused: false,
    };
  },
  computed: {
    ...mapState({
      anncmntLoopList: state => state.announcement.anncmntLoopList,
    }),
  },
  created() {
    this.fetchAnncmnLoopList({ projectId: this.projectId });
  },
  mounted() {
    setTimeout(() => {
      const w = window.getComputedStyle(this.$refs.marquee).width;
      this.$refs.wrapWidth?.style && (this.$refs.wrapWidth.style.marginLeft = w);
    }, 500);
  },
  methods: {
    ...mapActions(['fetchAnncmnLoopList']),
    handlClick({ id }) {
      this.$emit('click', id);
    },
    getMarqueeStyle() {
      let customStyleObj = null;
      try {
        customStyleObj = (typeof this.customStyle === 'object') ? this.customStyle : JSON.parse(this.customStyle);
      } catch (err) {
        // 解析错误
      }
      return isEmpty(customStyleObj)
        ? `color:${this.isDark ? '#fff' : '#5c5c5c'}`
        : customStyleObj;
    },
  },
});


</script>

<style lang="scss" scoped>
  .marquee{
    flex: 1;
    margin: 0 15px;
    height: 100%;
    overflow: hidden;
    .marquee-text-wrap{
      width: 100%;
      .marquee-text-content{
        height: 100%;
        width: max-content;
      }
    }
    .anncmnt-span{
      padding-right: 6px;
      cursor: pointer;
    }
  }
</style>


