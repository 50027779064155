<template>
  <div
    v-if="tagList.length"
    class="tag-view"
  >
    <a-tag
      v-if="tagList.length > 1"
      class="tag-link"
      @click="handlerRemoveOthers"
    >
      关闭其他
    </a-tag>
    <div
      ref="scrollContainer"
      class="tag-container"
    >
      <router-link
        v-for="tag in tagList"
        :key="tag.path"
        :to="tag.path"
        tag="span"
      >
        <a-tag
          :class="[`tag-link`, { 'tag-link-active': $route.fullPath === tag.path }]"
          :closable="tagList.length > 1"
          @close="(e) => handleRemoveTag(e, tag)"
        >
          {{ tag.name }}
        </a-tag>
      </router-link>
    </div>
    <div class="tag-setting">
      <a-icon
        class="cursor-pointer"
        type="setting"
      />
      <div class="tag-setting-content">
        <a-checkbox v-model="localTagListSwitch">
          本地缓存
        </a-checkbox>
      </div>
    </div>
  </div>
</template>

<script>
import { watch, computed } from '@vue/composition-api';
import { mapActions } from 'vuex';
import { localTagListSwitch, localTagList } from '@/store/navigation';
import { useStore } from '@/store';

export default {
  setup() {
    const store = useStore();

    const tagList = computed(() => store.state.navigation.tagList);
    const showMenu = computed(() => store.state.navigation.showMenu);
    const breadcrumb = computed(() => store.state.navigation.breadcrumb);
    const pageDetail = computed(() => store.state.runtime.currentPage);

    watch(localTagListSwitch, (v) => {
      localTagList.value = v ? tagList.value : [];
    });

    return {
      tagList,
      showMenu,
      breadcrumb,
      pageDetail,
      localTagListSwitch,
    };
  },
  mounted() {
    this.$watch(
      () => ({ route: this.$route, tagList: this.tagList }),
      () => this.$nextTick(this.handleUpdateActiveTag),
      {
        deep: true,
        immediate: true,
      },
    );
    this.$watch(
      () => this.$route,
      this.handleAddTag,
      {
        deep: true,
        immediate: true,
      },
    );
  },
  methods: {
    ...mapActions('navigation', ['addTag', 'removeTag', 'reserveTag', 'cleanTag', 'setBreadcrumb']),
    handleRemoveTag(e, tag) {
      e.preventDefault();
      const isActive = this.$route.fullPath === tag.path;
      // 关闭当前激活的标签时，跳转到下一个标签
      if (isActive) {
        const index = this.tagList.findIndex(item => item.path === tag.path);
        const nextTag = this.tagList[index + 1] ?? this.tagList[index - 1];
        if (nextTag) {
          this.$router.push(nextTag.path);
        }
      }
      this.removeTag(tag);
    },
    // 关闭其他标签
    handlerRemoveOthers() {
      const findIndex = this.tagList.findIndex(item => item.path === this.$route.fullPath);
      if (findIndex > -1) {
        this.reserveTag(findIndex);
      } else {
        this.cleanTag();
      }
    },
    // 默认是菜单栏更新标签，因为菜单栏有name，当菜单栏没有命中时，需要自行添加标签
    handleAddTag() {
      // 不存在菜单栏 或者 没有命中菜单栏
      if (!this.showMenu || !this.breadcrumb.length) {
        setTimeout(() => {
          const name = this.pageDetail?.name;
          if (name) {
            this.addTag({ name, path: this.$route.fullPath });
            this.setBreadcrumb([name]);
          }
        }, 50);
      }
    },
    // 更新激活标签的位置
    handleUpdateActiveTag() {
      const tagContainer = this.$refs.scrollContainer;
      const activeTag = tagContainer?.querySelector('.tag-link-active');
      if (activeTag) {
        activeTag.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'nearest' });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.tag-view {
  display: flex;
  align-items: center;
  padding: 8px 12px;
}

.tag-container {
  flex: 1;
  white-space: nowrap;
  overflow: scroll;
  position: relative;

  &::-webkit-scrollbar {
    width: 0;
    height: 0;
  }
}

.tag-link {
  cursor: pointer;
  padding: 4px 8px;
  background: white;
  border-color: #e5e5e5;

  &-active {
    color: var(--wuji-primary-color);
  }
}

.tag-setting {
  display: flex;
  align-items: center;
  flex-shrink: 1;
  padding-left: 12px;

  &:hover {
    .tag-setting-content {
      display: block;
    }
  }

  .tag-setting-content {
    display: none;
    margin-left: 16px;
  }
}
</style>
