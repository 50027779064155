import { cloneDeep } from 'lodash';

const FLOWNODE_START_EVENTS = {
  allDataSourcesReady: {
    steps: [
      {
        type: 'uicore:runScript',
        note: '发起时自动填表',
        params: {
          script: `
// 允许通过 url 里添加 #{"foo":"bar"} 提前自动填发起页表单
if (node.id === "start" && !node.readonly && window.location.hash.slice(1)) {
  try {
    const initArgs = JSON.parse(decodeURIComponent(window.location.hash.slice(1)));
    if (!node.form) $set(node, "form", {});
    Object.entries(initArgs).forEach(([key, value]) => {
      node.form[key] = value;
    });
  } catch (e) {
    console.log(e);
    console.log('初始化流程参数错误');
  }
}
          `.trim(),
        },
        _key: 'lguleos6',
      },
    ],
  },
};
export const DEFAULT_FLOWNODE_PAGELET = {
  id: 'default',
  name: '页面片',
  ucVersion: 2,
  xyType: 'pagelet',
  lessCode: '',
  layout: {
    type: 'xy:flowNodePageletWrapper',
    children: [
      {
        type: 'xy:autoFLowNodePagelet',
        componentId: 'xyAutoFLowNodePagelet',
      },
    ],
    events: FLOWNODE_START_EVENTS,
  },
};

/**
 * @type {import('../types').PageGenerator}
 */
export const flowNodePagelet = function ({ flowId, nodeId }, { projectId, pageId }) {
  return {
    getPageConfig() {
      const pagelet = cloneDeep(DEFAULT_FLOWNODE_PAGELET);

      return {
        projectId,
        pageId,
        title: '流程实例详情',
        pageData: {},
        pageConfig: [pagelet],
      };
    },
    getPluginList() {
      const pluginId = 'internal:w-flow-page-plugin';
      const id = [projectId, pageId, pluginId].join(':');
      return [
        {
          _id: id,
          id,
          pluginId,
          projectId,
          pageId,
          pluginConfig: JSON.stringify({
            flowId,
            pageType: 'nodePagelet',
            nodeId,
          }),
          detail: {},
        },
      ];
    },
  };
};
