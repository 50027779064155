import { isEqual } from 'lodash';
export class FormPropChild {
    parent;
    getter;
    get value() {
        return this.getter(this.parent.value);
    }
    get baseValue() {
        return this.getter(this.parent.baseValue);
    }
    get changed() {
        const { value, baseValue } = this;
        if (!this.parent.changed) {
            return false;
        }
        return !isEqual(value, baseValue);
    }
    constructor(parent, getter) {
        this.parent = parent;
        this.getter = getter;
    }
}
