import { MaterialContainer } from '../modules/material-container';
const xyMaterialContainer = new MaterialContainer();
export function defineSearchMaterials(configs) {
    const keys = Object.keys(configs);
    const ans = {
        configs,
    };
    keys.forEach((key) => {
        let prev;
        Object.defineProperty(ans, key, {
            get() {
                if (!prev)
                    prev = xyMaterialContainer.getById(key);
                return prev;
            },
        });
    });
    return ans;
}
export { xyMaterialContainer };
