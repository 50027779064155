import { isNewWorkbenchEnabled } from './workbench-enabled';
import { IS_DEV } from './env';
import { xyMaterialContainer } from './material-container';
const navigateTo = (targetLocation, mode) => {
    try {
        const $router = window?.xy_vue_instance?.$router;
        if (!$router)
            return;
        if (!mode) {
            // @ts-ignore
            if (window.event && (window.event.button === 1 || window.event.ctrlKey || window.event.shiftKey || window.event.metaKey))
                mode = 'newWindow'; // eslint-disable-line no-param-reassign
            else
                mode = 'push'; // eslint-disable-line no-param-reassign
        }
        if (mode === 'newWindow') {
            // 新窗口打开
            const resolved = $router.resolve(targetLocation);
            if (resolved.href)
                window.open(resolved.href, '_blank', 'noopener=yes');
            return;
        }
        $router[mode](targetLocation);
    }
    catch (error) {
    }
};
/**
 * 如果没有projectid，则尝试自动加下
 * @param query
 * @returns
 */
export function addQueryIfNeeded(query) {
    const route = window?.xy_vue_instance?.$route;
    const routeQuery = route?.query || {};
    const q = {
        ...query,
    };
    if (!q.projectid && routeQuery.projectid) {
        q.projectid = routeQuery.projectid;
    }
    // branch 路由自己会加，这边不加
    return q;
}
function routeFuncFactory(newLocation, oldLocation) {
    return (location) => {
        if (IS_DEV && newLocation.materialId) {
            if (!xyMaterialContainer.getById(newLocation.materialId)) {
                throw new Error(`[routeFactory] 无法找到对应的materialId：${newLocation.materialId}`);
            }
        }
        const isNew = isNewWorkbenchEnabled();
        const baseLocation = isNew ? newLocation : oldLocation;
        const finalQuery = addQueryIfNeeded(location.query || {});
        navigateTo({
            ...baseLocation,
            ...location,
            query: { ...baseLocation.query, ...finalQuery },
        }, location.mode);
    };
}
const wbRouteTools = {
    /**
     * 跳转工作台主页，旧工作台的跳pageList
     */
    gotoWorkbenchHome: routeFuncFactory({ materialId: 'home' }, { path: '/project/config/pagelist' }),
    gotoPageList: routeFuncFactory({ materialId: 'developPage' }, { path: '/project/config/pagelist' }),
    /**
     * 跳转数据源列表
     */
    gotoCollectionList: routeFuncFactory({ materialId: 'collection' }, { path: '/project/config/collection/list' }),
    /**
     * 高级流程列表
     */
    gotoFlowList: routeFuncFactory({ materialId: 'flowList' }, { path: '/project/config/flow' }),
    /**
     * 高级流程执行记录列表
     */
    gotoFlowJobList: routeFuncFactory({ materialId: 'flowJobList' }, { path: '/project/config/flow/job' }),
    /**
     * 跳转数据源日志
     */
    gotoDSLog: routeFuncFactory({ materialId: 'dsLog' }, { path: '/project/config/dslog' }),
    gotoExtNoticeSetting: routeFuncFactory({ materialId: 'extNoticeSetting' }, { path: '/project/config/advance' }),
    gotoVersionPublish: routeFuncFactory({ materialId: 'publishVersion' }, { path: '/project/config/version' }),
    getVersionPublishPath() {
        if (isNewWorkbenchEnabled()) {
            return '/xy/workbench/deployment/publish-version';
        }
        return '/xy/project/config/version';
    },
    gotoPublishSetting: routeFuncFactory({ materialId: 'publishSetting' }, { path: '/project/config/settings/publish' }),
    gotoPermissionRole: routeFuncFactory({ materialId: 'roleManagement' }, { path: '/project/config/permission/role' }),
    /**
     * 跳转到审批流
     */
    gotoWorkflow: routeFuncFactory({ materialId: 'workflow' }, { path: '/project/config/workflow' }),
    gotoWorkflowEdit: routeFuncFactory({ materialId: 'editWorkflowConfig' }, { path: 'workflow-edit' }),
    gotoWorkflowCreate: routeFuncFactory({ materialId: 'newWorkflowConfig' }, { path: '/project/config/workflow/create' }),
    gotoReportAnalysis: routeFuncFactory({ materialId: 'reportAnalysis' }, { path: '/project/config/report/analytics' }),
    gotoWXRobot: routeFuncFactory({ materialId: 'extNoticeSetting' }, { path: '/project/config/settings/advance' }),
    gotoComponentManagement: routeFuncFactory({ materialId: 'componentHub' }, { path: '/project/config/component' }),
};
export default wbRouteTools;
