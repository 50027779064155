import Router from 'vue-router';
import logger from '@utils/logger';
import { idToRouteName } from '@/workbench/common/route';
let injected = false;
let originalPush;
let originalReplace;
let originalResolve;
/**
 * 由于vue-router的路由守卫无法获取当前触发路由变化的是replace方法还是push方法
 *
 * 我们需要使用这个全局变量来进行记录
 *
 * 我们会在$router.replace方法被调用的时候将其设置为true，代码在'./injectRouter.ts'
 */
const isReplace = { value: false };
const setReplace = (val) => isReplace.value = val;
export const routeChangeIsReplace = () => isReplace.value;
// refs: https://github.com/vuejs/vue-router/issues/2881#issuecomment-520554378
export const injectRouterMethods = () => {
    if (injected)
        return;
    originalPush = Router.prototype.push;
    originalReplace = Router.prototype.replace;
    originalResolve = Router.prototype.resolve;
    // 注入的错误处理逻辑
    const errorHandler = (err) => {
        // 在导航守卫调用next(newLocation)重定向到其他地方
        if (Router.isNavigationFailure(err, Router.NavigationFailureType.redirected)) {
            logger.log('[VueRouter.errorHandler]', err);
            return err;
        }
        // 因为已经在目标位置被阻止导航
        if (Router.isNavigationFailure(err, Router.NavigationFailureType.duplicated)) {
            logger.log('[VueRouter.errorHandler]', err);
            return err;
        }
        // rethrow error
        return Promise.reject(err);
    };
    const setMaterialIdRouteName = (location) => {
        if (typeof location === 'string') {
            return location;
        }
        if (location.materialId) {
            return {
                ...location,
                name: idToRouteName(location.materialId),
            };
        }
        return location;
    };
    Router.prototype.push = function push(location, onComplete, onAbort) {
        const finalLocation = setMaterialIdRouteName(location);
        if (onComplete || onAbort) {
            return originalPush.call(this, finalLocation, onComplete, onAbort);
        }
        return originalPush.call(this, finalLocation).catch(errorHandler);
    };
    Router.prototype.replace = function replace(location, onComplete, onAbort) {
        const finalLocation = setMaterialIdRouteName(location);
        setReplace(true);
        let result = null;
        if (onComplete || onAbort) {
            result = originalReplace.call(this, finalLocation, onComplete, onAbort);
        }
        else {
            result = originalReplace.call(this, finalLocation).catch(errorHandler);
        }
        setReplace(false);
        return result;
    };
    Router.prototype.resolve = function resolve(location, ...args) {
        const finalLocation = setMaterialIdRouteName(location);
        return originalResolve.call(this, finalLocation, ...args);
    };
    injected = true;
};
