import { message } from 'ant-design-vue';
import { get } from 'lodash';

export const INITTOTAL = 0;
export const INITPAGE = 1;
export const POLL_INTERVAL_MS = 10000; // 10秒
export const SEMVER = 'semver';
export const OFFLINESTATE = {
  SUCCESS: 0,
  FAILURE: 1,
  PENDING: 2,
};
export const MESSAGES = {
  FAILURE: '任务失败，请重新导出',
  PENDING: '等待任务导出完成...',
  NULLURL: '下载链接为空，无法下载',
};
export const STATETAGLIST = new Map([
  [OFFLINESTATE.SUCCESS, {
    name: '成功',
    color: '#6abe73',
  }],
  [OFFLINESTATE.FAILURE, {
    name: '失败',
    color: '#cc0000',
  }],
  [OFFLINESTATE.PENDING, {
    name: '进行中',
    color: '#fb9754',
  }],
]);
export const COLUMNS = [
  {
    dataIndex: 'id',
    key: 'id',
    title: '序号',
    width: 120,
  },
  {
    dataIndex: SEMVER,
    key: SEMVER,
    title: '版本号',
    width: 120,
  },
  {
    dataIndex: 'versionDesc',
    key: 'versionDesc',
    title: '版本描述',
    width: '15%',
  },
  {
    dataIndex: 'pageIds',
    key: 'pageIds',
    title: '导出页面ID',
    width: '15%',
    scopedSlots: { customRender: 'pageIds' },
  },
  {
    dataIndex: 'operator',
    key: 'operator',
    title: '用户',
    width: '10%',
  },
  {
    dataIndex: 'state',
    key: 'state',
    title: '状态',
    width: 140,
    scopedSlots: { customRender: 'state' },
  },
  {
    dataIndex: '_ctime',
    key: '_ctime',
    title: '创建时间',
    width: 185,
    scopedSlots: { customRender: 'ctime' },
  },
  {
    key: 'actions',
    title: '操作',
    width: 230,
    scopedSlots: { customRender: 'actions' },
  },
];

// 检查是否有 PENDING 状态的任务
export function checkPending(offlineList) {
  return offlineList.some(item => item.state === OFFLINESTATE.PENDING);
};
// 工具方法：过滤版本号
export function filterOption(input, option) {
  const text = get(option, 'componentOptions.children[0].text', '').toUpperCase();
  return text.indexOf(input.toUpperCase()) >= 0;
};
// 下载离线化数据
export function handleDownload(state, url) {
  if (state === OFFLINESTATE.FAILURE) {
    // 如果 state 为 0，则提示任务未成功，不能下载
    message.error(MESSAGES.FAILURE);
  } else if (state === OFFLINESTATE.PENDING) {
    // 如果 state 为 1，则提示等待任务导出完成
    message.loading(MESSAGES.PENDING, 1);
  } else if (state === OFFLINESTATE.SUCCESS && url) {
    // 如果 state 为 2 并且 url 存在，则在新窗口中打开链接
    window.open(url, '_blank');
  } else {
    message.error(MESSAGES.NULLURL);
  }
};

// OfflineModal的常量,1表示下线页面，修复1.0数据库isOffline字段拉取失败问题
export const ISOFFLINE = 1;
export const ENV_IDS = {
  DEV: 'dev',
  PROD: 'prod',
};
export const DEFAULT_BRANCH_ID = 'master';

export const PAGECOLUMNS = [
  {
    dataIndex: 'pageId',
    key: 'pageId',
    width: '15%',
    slots: { title: 'pageId' },
    scopedSlots: { customRender: 'actions' },
  },
  {
    dataIndex: 'name',
    key: 'name',
    title: '页面名称',
    width: '15%',
  },
  {
    dataIndex: 'pageTag',
    key: 'pageTag',
    title: '页面标签',
    width: '15%',
    scopedSlots: { customRender: 'pageTag' },
  },
  {
    dataIndex: 'path',
    key: 'path',
    title: '页面路径',
  },
  {
    dataIndex: 'modifier',
    key: 'modifier',
    title: '最近修改人',
    width: '15%',
  },
  {
    dataIndex: 'mtime',
    key: 'mtime',
    title: '最近修改时间',
    scopedSlots: { customRender: 'mtime' },
  },
];

/*  迭代：增加离线任务导出步骤条:
1.可自定义离线任务配置，单选功能，配置函数hoosk支持调试
2.离线任务页面选择
3.离线任务导出预览
*/
export const DEBUGSUCCESS = 'Success';
export const FIRSTSTEP = 0;
export const SECONDSTEP = 1;
export const THIRDSTEP = 2;
export const STEPSTITLE = ['离线任务配置选择', '离线任务页面选择', '离线任务导出预览'];
export const CONFIG_KEY = 'offline_select_config';
export const HOOKS_KEY = 'hooks';
export const CONFIGCOLUMNS = [
  {
    dataIndex: 'id',
    key: 'id',
    title: '配置ID(单选)',
    width: '15%',
    slots: { title: 'configId' },
    scopedSlots: { customRender: 'configCheck' },
  },
  {
    dataIndex: 'configName',
    key: 'configName',
    title: '配置名称',
    width: '15%',
    scopedSlots: { customRender: 'configName' },
  },
  {
    dataIndex: 'desc',
    key: 'desc',
    title: '功能描述',
    width: '15%',
    scopedSlots: { customRender: 'configDesc' },
  },
  {
    key: 'branchAndEnv',
    title: '分支和环境',
    scopedSlots: { customRender: 'branchAndEnv' },
  },
  {
    dataIndex: 'operator',
    key: 'operator',
    title: '创建用户',
    width: '15%',
  },
  {
    key: 'actions',
    title: '操作',
    scopedSlots: { customRender: 'configActions' },
  },
];

// 自定义配置
export const CONFIGFIELDS = [
  {
    id: 'configName',
    title: '配置名称',
    placeholder: '请输入配置名称，如：默认配置',
  },
  {
    id: 'desc',
    title: '功能描述',
    placeholder: '请简要描述该配置的功能和用途',
  },
];
export const CONFIGOPTIONS = [
  {
    id: 'page',
    title: '页面',
    infoType: 'pageList',
    formattedInfoType: 'formattedPageList',
    defaultCode: `/**
* @param {pageInfo} pageInfo
*/
// 示例代码
async (pageInfo) => {
  return pageInfo;
}`,
  },
  {
    id: 'collection',
    title: '数据源',
    infoType: 'collectionList',
    formattedInfoType: 'formattedCollectionList',
    defaultCode: `/**
* @param {collectionInfo} collectionInfo
*/
// 示例代码
async (collectionInfo) => {
  return collectionInfo;
}`,
  },
];
export const CONFIG_ACTION = {
  UPDATE: 'update',
  CREATE: 'create',
};
// 格式化调试列表信息
export const formatOptions = (list, idKey, nameKey, tabKey) => list.map(item => ({
  value: item[idKey],
  label: `${item[nameKey]}(ID:${item[tabKey]})`,
}));
// 校验规则
export const RULES = {
  configName: [{ required: true, message: '配置名称不能为空', trigger: 'change' }],
  desc: [{ required: true, message: '功能描述不能为空', trigger: 'change' }],
};
// 格式化展示调试日志
export const formatCodeDebugPrint = (debugResult) => {
  const { message, logs, result } = debugResult;
  const formattedLogs = logs.reduce((prev, cur) => {
    const content = Array.isArray(cur.content)
      ? cur.content.reduce((prev, cur) => `${prev ? `${prev},` : ''} ${JSON.stringify(cur || '[]', null, 2)}`, '')
      : JSON.stringify(cur.content || '[]', null, 2);
    const current = `[${cur.level}] ${content}\n`;
    return prev + current;
  }, '');
  const formattedResult = JSON.stringify(result || '[]', null, 2);
  return `${formattedLogs}'message': ${message}\n'返回值': ${formattedResult}`;
};
// 代码参数提示
export const autoCompletionSchema = {
  type: 'object',
  fields: [
    {
      id: 'pageInfo',
      type: 'object',
      title: '页面信息',
      fields: [
        {
          id: 'id',
          type: 'string',
        },
        {
          id: 'pageId',
          type: 'string',
        },
        {
          id: 'projectId',
          type: 'string',
        },
        {
          id: 'name',
          type: 'string',
        },
        {
          id: 'title',
          type: 'string',
        },
        {
          id: 'layoutType',
          type: 'string',
        },
        {
          id: 'path',
          type: 'string',
        },
        {
          id: 'type',
          type: 'string',
        },
        {
          id: 'creator',
          type: 'string',
        },
        {
          id: 'modifier',
          type: 'string',
        },
        {
          id: 'ctime',
          type: 'string',
        },
        {
          id: 'mtime',
          type: 'string',
        },
        {
          id: 'previewImageUrl',
          type: 'object',
        },
        {
          id: 'pageTag',
          type: 'object',
        },
        {
          id: 'pageType',
          type: 'string',
        },
        {
          id: 'pageAttr',
          type: 'object',
          fields: [
            {
              id: 'navFixTop',
              type: 'string',
            },
            {
              id: 'customNav',
              type: 'string',
            },
            {
              id: 'customSidebar',
              type: 'string',
            },
            {
              id: 'customHeader',
              type: 'string',
            },
            {
              id: 'customFooter',
              type: 'string',
            },
            {
              id: 'showWatermark',
              type: 'string',
            },
            {
              id: 'keepAlive',
              type: 'boolean',
            },
            {
              id: 'isMobile',
              type: 'boolean',
            },
          ],
        },
        {
          id: 'isOffline',
          type: 'number',
        },
      ],
    },
    {
      id: 'collectionInfo',
      type: 'object',
      title: '数据源信息',
      fields: [
        {
          id: 'id',
          type: 'string',
        },
        {
          id: 'collectionId',
          type: 'string',
        },
        {
          id: 'projectId',
          type: 'string',
        },
        {
          id: 'name',
          type: 'string',
        },
        {
          id: 'tag',
          type: 'string',
        },
        {
          id: 'desc',
          type: 'string',
        },
        {
          id: 'tplType',
          type: 'string',
        },
        {
          id: 'type',
          type: 'string',
        },
        {
          id: 'cgiInfo',
          type: 'object',
          title: '数据源接口配置的引用，内部存储着当前请求的所有配置，可以动态修改',
          description: '[参考文档](https://docs.wuji.woa.com/page/doc?id=e40e8727b63d3823#contextcgiInfo) <br>[【HTTP】配置项列表](https://docs.wuji.woa.com/page/doc?id=fae0bf68a8a5350f#%E9%85%8D%E7%BD%AE%E9%A1%B9%E5%88%97%E8%A1%A8) <br>[使用例子](https://docs.wuji.woa.com/page/doc?id=4a8434c2b69946cd)',
          required: true,
          fields: [
            {
              id: 'request',
              type: 'object',
              fields: [
                {
                  id: 'url',
                  type: 'string',
                  title: '请求发送到目标服务器上的地址，可以动态修改',
                  description: '[参考文档](https://docs.wuji.woa.com/page/doc?id=4a8434c2b69946cd)',
                  required: true,
                },
                {
                  id: 'type',
                  type: 'string',
                  title: '请求方法',
                  required: true,
                },
                {
                  id: 'cgiType',
                  type: 'number',
                },
                {
                  id: 'timeout',
                  type: 'number',
                  title: '无极数据源等待业务后台数据接口的响应时间，单位MS',
                  required: true,
                },
                {
                  id: 'https',
                  type: 'boolean',
                },
                {
                  id: 'dnsType',
                  type: 'number',
                  title: '寻址类型，0: 北极星, 1: CL5, 2: 域名',
                  required: true,
                },
                {
                  id: 'debugEnv',
                  type: 'string',
                },
                {
                  id: 'debugParam',
                  type: 'object',
                  fields: [
                    {
                      id: 'query',
                      type: 'object',
                      fields: [
                        {
                          id: 'appid',
                          type: 'string',
                        },
                        {
                          id: 'schemaid',
                          type: 'string',
                        },
                      ],
                    },
                    {
                      id: 'body',
                      type: 'object',
                      fields: [],
                    },
                  ],
                },
                {
                  id: 'extra',
                  type: 'object',
                  fields: [],
                },
                {
                  id: 'autoUpdateInputSchema',
                  type: 'boolean',
                },
                {
                  id: 'id',
                  type: 'string',
                },
                {
                  id: 'isMock',
                  type: 'boolean',
                },
                {
                  id: 'mockDataFormat',
                  type: 'number',
                },
                {
                  id: 'mockJSONData',
                  type: 'string',
                },
                {
                  id: 'mockCodeData',
                  type: 'string',
                },
                {
                  id: 'projectId',
                  type: 'string',
                },
                {
                  id: 'name',
                  type: 'string',
                },
                {
                  id: 'service',
                  type: 'string',
                  title: '寻址服务名',
                  required: true,
                },
                {
                  id: 'parseBigInt',
                  type: 'boolean',
                },
                {
                  id: 'resttype',
                  type: 'string',
                },
                {
                  id: 'status',
                  type: 'number',
                },
                {
                  id: 'envMap',
                  type: 'object',
                  title: '环境路由寻址，可以动态设置接口在不同环境下的寻址服务名',
                  required: true,
                },
                {
                  id: 'customHeaders',
                  type: 'object',
                  title: '后端转发请求时需要额外发送的后端请求头',
                  description: '[参考文档](https://docs.wuji.woa.com/page/doc?id=71c908d38e8ed69b)',
                  required: true,
                  fields: [],
                },
                {
                  id: 'querySchema',
                  type: 'object',
                  fields: [],
                },
                {
                  id: 'bodySchema',
                  type: 'object',
                  fields: [],
                },
                {
                  id: 'responseSchema',
                  type: 'object',
                  fields: [
                    {
                      id: 'type',
                      type: 'string',
                    },
                    {
                      id: 'fields',
                      type: 'array',
                    },
                  ],
                },
                {
                  id: 'fixedQuerySchema',
                  type: 'object',
                  fields: [
                    {
                      id: 'id',
                      type: 'string',
                    },
                    {
                      id: 'type',
                      type: 'string',
                    },
                    {
                      id: 'fields',
                      type: 'array',
                    },
                  ],
                },
                {
                  id: 'fixedBodySchema',
                  type: 'object',
                  fields: [
                    {
                      id: 'id',
                      type: 'string',
                    },
                    {
                      id: 'type',
                      type: 'string',
                    },
                    {
                      id: 'fields',
                      type: 'array',
                    },
                  ],
                },
                {
                  id: 'fixedResponseSchema',
                  type: 'object',
                  fields: [
                    {
                      id: 'type',
                      type: 'string',
                    },
                    {
                      id: 'fields',
                      type: 'array',
                    },
                  ],
                },
                {
                  id: 'disableAutoRedirect',
                  type: 'boolean',
                },
              ],
            },
            {
              id: 'list',
              type: 'object',
              fields: [
                {
                  id: 'id',
                  type: 'string',
                },
                {
                  id: 'name',
                  type: 'string',
                },
                {
                  id: 'type',
                  type: 'string',
                },
                {
                  id: 'desc',
                  type: 'string',
                },
                {
                  id: 'clipperSchema',
                  type: 'string',
                },
                {
                  id: 'debugEnv',
                  type: 'string',
                },
                {
                  id: 'debugParams',
                  type: 'object',
                  fields: [
                    {
                      id: 'query',
                      type: 'object',
                      fields: [
                        {
                          id: 'page',
                          type: 'string',
                        },
                        {
                          id: 'size',
                          type: 'string',
                        },
                        {
                          id: 'filter',
                          type: 'string',
                        },
                        {
                          id: 'include',
                          type: 'string',
                        },
                        {
                          id: 'exclude',
                          type: 'string',
                        },
                        {
                          id: 'sort',
                          type: 'string',
                        },
                        {
                          id: 'order',
                          type: 'string',
                        },
                      ],
                    },
                    {
                      id: 'body',
                      type: 'object',
                      fields: [],
                    },
                  ],
                },
              ],
            },
            {
              id: 'create',
              type: 'object',
              fields: [
                {
                  id: 'id',
                  type: 'string',
                },
                {
                  id: 'name',
                  type: 'string',
                },
                {
                  id: 'type',
                  type: 'string',
                },
                {
                  id: 'desc',
                  type: 'string',
                },
                {
                  id: 'clipperSchema',
                  type: 'string',
                },
              ],
            },
            {
              id: 'update',
              type: 'object',
              fields: [
                {
                  id: 'id',
                  type: 'string',
                },
                {
                  id: 'name',
                  type: 'string',
                },
                {
                  id: 'type',
                  type: 'string',
                },
                {
                  id: 'desc',
                  type: 'string',
                },
                {
                  id: 'clipperSchema',
                  type: 'string',
                },
              ],
            },
            {
              id: 'get',
              type: 'object',
              fields: [
                {
                  id: 'id',
                  type: 'string',
                },
                {
                  id: 'name',
                  type: 'string',
                },
                {
                  id: 'type',
                  type: 'string',
                },
                {
                  id: 'desc',
                  type: 'string',
                },
                {
                  id: 'clipperSchema',
                  type: 'string',
                },
              ],
            },
            {
              id: 'delete',
              type: 'object',
              fields: [
                {
                  id: 'id',
                  type: 'string',
                },
                {
                  id: 'name',
                  type: 'string',
                },
                {
                  id: 'type',
                  type: 'string',
                },
                {
                  id: 'desc',
                  type: 'string',
                },
                {
                  id: 'clipperSchema',
                  type: 'string',
                },
              ],
            },
          ],
        },
        {
          id: 'cgiPlugins',
          type: 'object',
          fields: [
            {
              id: 'list',
              type: 'array',
            },
            {
              id: 'count',
              type: 'array',
            },
            {
              id: 'create',
              type: 'array',
            },
            {
              id: 'update',
              type: 'array',
            },
            {
              id: 'get',
              type: 'array',
            },
            {
              id: 'delete',
              type: 'array',
            },
            {
              id: 'prodList',
              type: 'array',
            },
            {
              id: 'deleteOpList',
              type: 'array',
            },
            {
              id: 'historyList',
              type: 'array',
            },
            {
              id: 'unpublishedList',
              type: 'array',
            },
            {
              id: 'publish',
              type: 'array',
            },
            {
              id: 'publishDeleteOp',
              type: 'array',
            },
            {
              id: 'rollback',
              type: 'array',
            },
            {
              id: 'getPublishStatus',
              type: 'array',
            },
            {
              id: 'prodCount',
              type: 'array',
            },
            {
              id: 'deleteOpCount',
              type: 'array',
            },
            {
              id: 'historyCount',
              type: 'array',
            },
            {
              id: 'publishedList',
              type: 'array',
            },
            {
              id: 'publishedCount',
              type: 'array',
            },
            {
              id: 'unpublishedCount',
              type: 'array',
            },
          ],
        },
        {
          id: 'afterPlugins',
          type: 'object',
          fields: [
            {
              id: 'list',
              type: 'array',
            },
            {
              id: 'count',
              type: 'array',
            },
            {
              id: 'create',
              type: 'array',
            },
            {
              id: 'update',
              type: 'array',
            },
            {
              id: 'get',
              type: 'array',
            },
            {
              id: 'delete',
              type: 'array',
            },
            {
              id: 'prodList',
              type: 'array',
            },
            {
              id: 'deleteOpList',
              type: 'array',
            },
            {
              id: 'historyList',
              type: 'array',
            },
            {
              id: 'unpublishedList',
              type: 'array',
            },
            {
              id: 'publish',
              type: 'array',
            },
            {
              id: 'publishDeleteOp',
              type: 'array',
            },
            {
              id: 'rollback',
              type: 'array',
            },
            {
              id: 'getPublishStatus',
              type: 'array',
            },
            {
              id: 'prodCount',
              type: 'array',
            },
            {
              id: 'deleteOpCount',
              type: 'array',
            },
            {
              id: 'historyCount',
              type: 'array',
            },
            {
              id: 'publishedList',
              type: 'array',
            },
            {
              id: 'publishedCount',
              type: 'array',
            },
            {
              id: 'unpublishedCount',
              type: 'array',
            },
          ],
        },
        {
          id: 'envTable',
          type: 'object',
          fields: [],
        },
        {
          id: 'entity',
          type: 'object',
          fields: [
            {
              id: 'type',
              type: 'string',
            },
            {
              id: 'fields',
              type: 'array',
            },
          ],
        },
        {
          id: 'stateModel',
          type: 'array',
        },
        {
          id: 'settings',
          type: 'object',
          fields: [
            {
              id: 'entityWujiIds',
              type: 'object',
              fields: [
                {
                  id: 'appId',
                  type: 'string',
                },
                {
                  id: 'schemaId',
                  type: 'string',
                },
              ],
            },
            {
              id: 'defaultSchema',
              type: 'string',
            },
            {
              id: 'originalSchema',
              type: 'object',
              fields: [
                {
                  id: 'type',
                  type: 'string',
                },
                {
                  id: 'fields',
                  type: 'array',
                },
              ],
            },
            {
              id: 'clipperSchema',
              type: 'object',
              fields: [
                {
                  id: 'clippers',
                  type: 'array',
                },
              ],
            },
          ],
        },
        {
          id: 'multiConfig',
          type: 'object',
          fields: [
            {
              id: 'baseConfigList',
              type: 'array',
            },
            {
              id: 'relationConfigList',
              type: 'array',
            },
          ],
        },
        {
          id: 'versionControl',
          type: 'number',
        },
        {
          id: 'creator',
          type: 'string',
        },
        {
          id: 'modifier',
          type: 'string',
        },
        {
          id: '_ctime',
          type: 'string',
        },
        {
          id: '_mtime',
          type: 'string',
        },
        {
          id: '_id',
          type: 'string',
        },
      ],
    },
  ],
};

