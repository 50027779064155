import { has } from 'lodash';
import { bizContainer } from '../biz/biz-container';
export class MaterialContainer {
    map = new Map();
    list = [];
    register(id, config) {
        if (!config) {
            throw new Error('MaterialContainer: config 不能为空');
        }
        if (this.map.has(id)) {
            throw new Error(`MaterialContainer: id ${id} 已经被占用，请更换其他id`);
        }
        if (has(config, 'hidden')) {
            if (config.hidden === true || (typeof config.hidden === 'function' && config.hidden())) {
                return;
            }
        }
        const m = makeMaterial(id, config);
        this.map.set(id, m);
        this.list.push(m);
        if (m.type !== 'route') {
            return m;
        }
        m.children?.forEach((child) => {
            // eslint-disable-next-line no-param-reassign
            child.parent = id;
            // eslint-disable-next-line no-param-reassign
            child.isChildRoute = true;
            this.register(child.id, child);
        });
        return m;
    }
    registerByMap(configs, mixin) {
        Object.keys(configs).forEach((id) => {
            const config = configs[id];
            if (!config)
                return;
            if (mixin) {
                Object.assign(config, mixin);
            }
            this.register(id, config);
        });
    }
    loadFieldMaterials(map) {
        const materials = {};
        Object.entries(map).forEach((param) => {
            const [key, path] = param;
            materials[key] = this.getByBizFieldPath(path);
        });
        return materials;
    }
    getByBizFieldPath(path) {
        const m = this.getById(path) || this.register(path, bizContainer.getBizField(path));
        return m;
    }
    getById(id) {
        const m = this.map.get(id);
        return m ?? null;
    }
    /**
     * 获取多个 id 对应的 material
     * @param ids
     * @returns
     */
    getArrayByIds(ids) {
        return ids.map(id => this.getById(id));
    }
    /**
     * 获取多个 id 对应的 material 的 map
     * eg: getMapByIds(['a', 'b'] as const); as const 可以有字段提示
     * @param ids
     * @returns
     */
    getMapByIds(ids) {
        return ids.reduce((acc, id) => {
            const m = this.getById(id);
            if (m) {
                acc[id] = m;
            }
            return acc;
        }, {});
    }
    filter(params) {
        const infos = Object.entries(params);
        /// @ts-ignore
        return this.list.filter(item => infos.every(([key, val]) => item[key] === val));
    }
    filterToMap(params) {
        const list = this.filter(params);
        return list.reduce((acc, item) => {
            // @ts-ignore
            acc[item.id] = item;
            return acc;
        }, {});
    }
    getRootParent(material) {
        const findParent = (material) => {
            if (!material.parent)
                return material;
            const { parent } = material;
            const parentMaterial = this.getById(parent);
            if (!parentMaterial)
                return material;
            return findParent(parentMaterial);
        };
        return findParent(material);
    }
    getParents(material) {
        const materials = [];
        const findParent = (material) => {
            if (!material.parent)
                return;
            const { parent } = material;
            const parentMaterial = this.getById(parent);
            if (!parentMaterial)
                return;
            materials.push(parentMaterial);
            return findParent(parentMaterial);
        };
        findParent(material);
        return materials;
    }
}
function makeMaterial(id, config) {
    const m = {
        id,
        ...config,
    };
    if (!m.type) {
        m.type = 'el';
    }
    return m;
}
