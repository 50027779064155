import { getDocLink } from '@utils/links';
import XyPageContainer from './xy-page-container.vue';
import XyPageSchema from './xy-page-schema';
import { XyPageletPropsEditorMeta } from '../uicorePlugin/paramsFormComponents';
import { XyPageContainerTipsMeta, XyInnerPageSelectorMeta, XyInnerPageletSelectorMeta, XyInnerFlowNodePageletConfigMeta } from './meta';

const propPanel = {
  XyInnerPageSelector: XyInnerPageSelectorMeta,
  XyInnerPageletSelector: XyInnerPageletSelectorMeta,
  XyInnerFlowNodePageletConfig: XyInnerFlowNodePageletConfigMeta,
  XyPageletPropsEditor: XyPageletPropsEditorMeta,
  XyPageContainerTips: XyPageContainerTipsMeta,
};

// if (location.href.includes('/pageconfig')) {
//   // 拆包
//   import('./xy-inner-page-selector.vue').then((r) => {
//     propPanel.XyInnerPageSelector = r.meta;
//   });
//   import('./xy-inner-pagelet-selector.vue').then((r) => {
//     propPanel.XyInnerPageletSelector = r.meta;
//   });
//   import('./xy-inner-flow-node-pagelet-config.vue').then((r) => {
//     propPanel.XyInnerFlowNodePageletConfig = r.meta;
//   });
// }

export default {
  component: XyPageContainer,
  componentForDesign: XyPageContainer,
  category: '插入',
  id: 'xy-page-container',
  methods: [
    {
      id: 'refresh',
      title: '触发刷新方法',
      arguments: [],
      returnValue: {},
    },
  ],
  events: [
    {
      id: 'presetEventA',
      name: '预置事件A',
      desc: '通过 `触发页面片事件` 行为触发该事件执行',
      arguments: [
        {
          type: 'any',
          description: '触发事件行为的自定义传参',
        },
      ],
    },
    {
      id: 'presetEventB',
      name: '预置事件B',
      desc: '通过 `触发页面片事件` 行为触发该事件执行',
      arguments: [
        {
          type: 'any',
          description: '触发事件行为的自定义传参',
        },
      ],
    },
    {
      id: 'presetEventC',
      name: '预置事件C',
      desc: '通过 `触发页面片事件` 行为触发该事件执行',
      arguments: [
        {
          type: 'any',
          description: '触发事件行为的自定义传参',
        },
      ],
    },
    {
      id: 'presetEventD',
      name: '预置事件D',
      desc: '通过 `触发页面片事件` 行为触发该事件执行',
      arguments: [
        {
          type: 'any',
          description: '触发事件行为的自定义传参',
        },
      ],
    },
    {
      id: 'presetEventE',
      name: '预置事件E',
      desc: '通过 `触发页面片事件` 行为触发该事件执行',
      arguments: [
        {
          type: 'any',
          description: '触发事件行为的自定义传参',
        },
      ],
    },
  ],
  icon: 'https://vfiles.gtimg.cn/wuji_dashboard/xy/rainbow/icon-page-container_1636364657577.png',
  slots: [],
  display: 'block',
  visible: true,
  name: '内嵌页面(片)',
  readme: getDocLink().PAGELET_DOC ?? '',
  order: 1,
  propPanel,
  propsSchema: XyPageSchema,
  // 预设值
  presets: [],
};
