import { message } from 'ant-design-vue';
import { map, compact } from 'lodash';

import AnncmntService from '@/services/announcement';

export default {
  state: {
    anncmntList: [],
    anncmntLoopList: [],
    classifications: new Set(),
  },
  getters: {
    getListOfStyle: state => style => state.anncmntList.filter(anncmnt => anncmnt.style === style),
  },
  actions: {
    async fetchAnncmntList({ commit, rootState }, { projectId, filter = '' } = {}) {
      try {
        const projectID = projectId || rootState.project.projectInfo?.id;
        const res = await AnncmntService.fetchAnncmntList(projectID, filter);
        if (!filter) {
          // 仅在全量获取list时更新分类列表
          const classifications = new Set(compact(map(res, 'classification')));
          commit('updateClassifications', classifications);
        }
        commit('updateAnncmntList', res);
      } catch (err) {
        message.error(`获取项目公告列表失败: ${err.message}`);
      }
    },
    async deleteAnncmnt({ commit, state, rootState }, { projectId, id } = {}) {
      try {
        const projectID = projectId || rootState.project.projectInfo?.id;
        await AnncmntService.deleteAnncmnt({ projectId: projectID, id });
        const newList = state.anncmntList.filter(item => item.id !== id);
        commit('updateAnncmntList', newList);
        message.success('已删除公告');
      } catch (err) {
        message.error(`删除公告失败: ${err.message}`);
      }
    },
    async updateAnncmnt({ commit, state, rootState }, { projectId, id, data } = {}) {
      try {
        const projectID = projectId || rootState.project.projectInfo?.id;
        const res = await AnncmntService.updateAnncmnt({ projectId: projectID, id, data });
        const newList = [...state.anncmntList];
        const index = newList.findIndex(item => item.id === id);
        newList[index] = res;
        commit('updateAnncmntList', newList);
        if (data?.classification) {
          commit('updateClassifications', state.classifications.add(data?.classification));
        }
        message.success('已更新公告');
        return true;
      } catch (err) {
        message.error(`更新公告失败: ${err.message}`);
        return false;
      }
    },
    async createAnncmnt({ commit, state, rootState }, { projectId, formData } = {}) {
      try {
        const projectID = projectId || rootState.project.projectInfo?.id;
        const res = await AnncmntService.createAnncmnt({ projectId: projectID, data: formData });
        const newList = [res, ...state.anncmntList];
        commit('updateAnncmntList', newList);
        if (formData?.classification) {
          commit('updateClassifications', state.classifications.add(formData?.classification));
        }
        message.success('已创建公告');
        return true;
      } catch (err) {
        message.error(`创建公告失败: ${err.message}`);
        return false;
      }
    },
    async fetchAnncmntRuntimeList({ commit, rootState }, { projectId, filter = '' } = {}) {
      try {
        const projectID = projectId || rootState.project.projectInfo?.id;
        const res = await AnncmntService.fetchAnncmntRuntimeList(projectID, filter);
        if (!filter) {
          // 仅在全量获取list时更新分类列表
          const classifications = new Set(compact(map(res, 'classification')));
          commit('updateClassifications', classifications);
        }
        commit('updateAnncmntList', res);
      } catch (err) {
        console.error(`获取项目公告列表失败: ${err.message}`);
      }
    },
    async fetchAnncmnLoopList({ commit, rootState }, { projectId } = {}) {
      try {
        const projectID = projectId || rootState.project.projectInfo?.id;
        const res = await AnncmntService.fetchAnncmnLoopList(projectID);
        commit('updateAnncmntLoopList', res);
      } catch (err) {
        console.error(`获取项目走马灯公告列表失败: ${err.message}`);
      }
    },
    async readAnncmntRuntime({ commit, state, rootState }, { projectId, id, endTime } = {}) {
      try {
        const projectID = projectId || rootState.project.projectInfo?.id;
        await AnncmntService.readAnncmntRuntime(projectID, id, endTime);
        const newList = [...state.anncmntList];
        const index = newList.findIndex(item => item.id === id);
        newList[index].read = true;
        commit('updateAnncmntList', newList);
      } catch (err) {
        message.error(`修改已读状态失败: ${err.message}`);
      }
    },
  },
  mutations: {
    updateAnncmntList(state, payload) {
      state.anncmntList = payload;
    },
    updateAnncmntLoopList(state, payload) {
      state.anncmntLoopList = payload;
    },
    updateClassifications(state, payload) {
      if (
        state.classifications?.size === payload.size
        && [...state.classifications].every(item => payload.has(item))
      ) return; // not changed
      state.classifications = new Set(payload);
    },
  },
};
