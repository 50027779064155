import { inject, provide } from '@vue/composition-api';
export function defineInjection(use, injectKey) {
    const token = Symbol(injectKey);
    const provideFunc = (...args) => {
        const state = use(...args);
        provide(token, state);
        return state;
    };
    const injectFunc = () => inject(token);
    return {
        provideFunc,
        injectFunc,
        injectKey: token,
    };
}
export function definePassThrough() {
    const { injectFunc, provideFunc } = defineInjection((t) => t);
    return [provideFunc, injectFunc];
}
